import { Button } from "@vds/buttons";
import { Grid, Row } from "@vds/grids";
import { SLGridLayout, SLRowLayout} from "../../components/core/Utility"
import { Loader } from "@vds/loaders";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { Notification } from "@vds/notifications";
import { Body } from "@vds/typography";
import { lazy, useState } from "react";
import { generate } from "shortid";
import { Header, SLButton, SLInput } from "../../components/core/Controls";
import { CompactCol } from "../../components/core/Utility";
import { ICCID_REGEX } from "../../constants/ValidationConstants";
import { swapSimByESN } from "../../services/VTU/swapDevices";

export default function SwapSim({ esn, iccid, simDataForAccount }) {
  const [notification, setNotification] = useState();
  const [newSim, setNewSim] = useState("");
  const [newSimError, setNewSimError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const simDataForAccountJSON = JSON.parse(simDataForAccount);

  const newSimChange = (e) => {
    if (newSimError) setNewSimError(false);
    setNewSim(e.target.value);
    let matchingData = simDataForAccountJSON.find((item) => item.Spare_SIM__c == e.target.value);
    setPhoneNumber(matchingData ? matchingData.GSM__c : "");
  };
  const notify = (content, type) => {
    setNotification(<Notification key={generate()} type={type} title={content} fullBleed={true} />);
  };
  const swapSim = async () => {
    if (!newSim.match(ICCID_REGEX)) {
      setNewSimError(true);
      return;
    }
    setLoading(true);
    let idForPhoneNoAndNewSim = simDataForAccountJSON.find((item) => item.GSM__c == phoneNumber && item.Spare_SIM__c == newSim);
    if (idForPhoneNoAndNewSim) {
      let payload = { id: idForPhoneNoAndNewSim.Id, esn: esn, phoneNumber: phoneNumber, iccid: iccid, newSim: newSim };
      let response = await swapSimByESN(payload);
      if (response.status === 200) {
        notify("Sim successfully swapped", "success");
      } else {
        notify("Sim Swap failed", "error");
      }
    } else {
      setNewSimError(true);
    }
    setLoading(false);
  };
  return (
    <>
      <Header>Swap Sim</Header>
      {notification}
      <Loader active={loading} />
      <Modal opened={alertModal} disableOutsideClick closeButton={null}>
        <ModalBody>
          <Body size="large" bold>
            We are currently experiencing difficulty in processing your request. Please try again sometime.
          </Body>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setAlertModal(false)}>OK</Button>
        </ModalFooter>
      </Modal>
      <SLGridLayout colSizes={{ mobile: 4, tablet: 6, desktop: 6 }}>
        <SLRowLayout>
          <CompactCol>
            <SLInput value={esn} readOnly type="text" label="ESN" />
            <SLInput value={iccid} readOnly type="text" label="Current SIM" />
            <SLInput
              value={newSim}
              onChange={newSimChange}
              type="text"
              label="New SIM"
              error={newSimError}
              errorText="Please enter the correct New Sim value"
            />
            <SLInput value={phoneNumber} readOnly type="text" label="New Phone#" />
            <SLButton onClick={swapSim}>Submit</SLButton>
          </CompactCol>
        </SLRowLayout>
      </SLGridLayout>
    </>
  );
}

export const SwapSimMetadata = {
  name: "Swap Sim",
  id: "swap_sim",
  component: lazy(() => import("./SwapSim")),
  route: "/swap_sim",
};
