import React, { lazy, useCallback, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { css } from "@emotion/react";
import { Input } from "@vds/inputs";
import { Grid, Row } from "@vds/grids";
import { SLGridLayout, SLRowLayout } from "../../components/core/Utility/index";
import { DropdownSelect } from "@vds/selects";
import { Header, SLButton } from "../../components/core/Controls";
import { CompactCol, CustomSLButton, FlexRow, MediumText, SmallText, Spacer, UltraMediumText } from "../../components/core/utility";
import { Notification } from "@vds/notifications";
import { generate } from "shortid";
import { ZIP_REGEX, VIN_REGEX, MAKE_REGEX, MODEL_REGEX } from "../../constants/C4SValidationConstants";
import { getFullName, getInstallType, redirectToPrevPage, TYPE_DROPDOWN_VALUES } from "./utils";
import { ValidateAddress } from "./ValidateAddress";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { ADD_VEHICLE_MMID_COL } from "../../constants/AddAVehicleConstants";
import { Icon } from "@vds/icons";
import edit from "../../assets/icons/edit.png";
import { AddVehicleMMIDModal } from "./AddVehicleMMIDModal";
import _ from "lodash";
import DeleteVehicleMMIDModal from "./DeleteVehicleMMIDModal";
import FailedIcon from "../../assets/icons/ErrorIcon.svg";
import { C4SContext } from "../../context/c4sContext";
import { AddMoreVehiclesModal } from "./AddMoreVehiclesModal";
import { QUANTITY_REGEX } from "../../constants/ValidationConstants";
import { ModalBody } from "@vds/modals";
import "./c4sDecomCustom.css";
import { Loader } from "@vds/loaders";
import { addVehicle } from "../../services/C4S Decommission - Replacement/addVehicle";
import { DISTRIBUTION_CHANNEL_DROPDOWN_VALUES, DIVISION_DROPDOWN_VALUES } from "../../constants/C4SDecomConstants";
import { editVehicle } from "../../services/C4S Decommission - Replacement/editVehicle";
import useOutsideClick from "../../customHook/use-outside-click";

export const MMIDErrorNotification = styled(Notification)`
  > div {
    background-color: #fbe4d7;
    border-radius: 5px;
    height: 45px;
    align-items: center;
  }
`;

export const StyledCol = css`
  & div {
    @media screen and (min-width: 1260px) {
      // width: 99.5%;
      padding-right: 0.15em;
    }
  }
`;

export const ColumnAlignment = css`
  @media screen and (min-width: 700px) and (max-width: 1024px) {
    padding-right: 5px;
  }
`;

const VINInputCss = css`
  input {
    text-transform: uppercase;
  }
  padding-left: 16px;
`;

export const CustomDropdown = styled(DropdownSelect)`
  & div {
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
    padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
    & label {
      & p {
        font-size: 12px;
        color: ${(props) => (props.disabled ? "#d8dada" : "")};
        & span {
          &::after {
            color: #747676;
          }
        }
      }
    }
    & span{
      & span{
        & div{
          & span{
            & svg{
              display:${(props) => (props.hideErrorImg ? "none !important" : "")};
            }
          }
        }
      }
    }
  }
  }
  & select {
    font-size: 12px;
    color: ${(props) => (props.disabled || props.disableValue ? "#666b6b" : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      // color: ${(props) => (props.disabled || props.disableValue ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
`;
export const CustomInput = styled(Input)`
  padding-bottom: 0.6rem;
  height: auto;
  width: ${(props) => (props.formWidth ? props.formWidth : "")};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
  & label {
    & p {
      font-size: 12px;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
    }
  }
  & input {
    font-size: 12px;
    height: ${(props) => (props.height ? props.height : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      border-top: 1px solid #dfe0e0;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
  & span {
    width: ${(props) => (props.width ? props.width : "")};
    & span {
      & span {
        & div:not(:first-child) {
          & svg {
            display: none; // hide success tick icon
          }
        }
      }
    }
  }
`;

export default function AddAVehicle({ vehicleData }) {
  const { isSourceCSP, cspDetails, notification, workTicketInfo } = useContext(C4SContext);
  const [vin, setVin] = useState({
    value: "",
    error: false,
    errorText: "Enter a valid VIN",
    success: false,
  });
  const [address, setAddress] = useState({
    verified: false,
    addressByPass: null,
    locationFound: null,
    timezone: "",
    historicalAddress: { value: "", error: false, errorText: "", success: false },
    address1: { value: "", error: false, errorText: "Enter valid street address", success: false },
    address2: { value: "", error: false, errorText: "Enter address 2", success: false },
    city: { value: "", error: false, errorText: "Enter city", success: false },
    country: { value: null, options: [], error: false, errorText: "Please select country", success: false },
    state: { value: null, options: [], allStateOptions: null, error: false, errorText: "Please select state", success: false },
    zip: { value: "", error: false, errorText: "Enter zip code", success: false },
  });
  const [year, setYear] = useState({ value: "", error: false, errorText: "Enter valid year", success: false });
  const [make, setMake] = useState({ value: "", error: false, errorText: "Enter valid make", success: false });
  const [model, setModel] = useState({ value: "", error: false, errorText: "Enter valid model", success: false });
  const [installType, setInstallType] = useState({ value: "", error: false, errorText: "Select install type", success: false });
  // const [notification, setNotification] = useState(null);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);
  const [salesOrg, setSalesOrg] = useState({ value: "", error: false, errorText: "Please validate the Sales Org.", success: false });
  const [vehicleMMIDTableList, setVehicleMMIDTableList] = useState([]);
  const [addVehicleMMIDModal, setAddVehicleMMIDModal] = useState(false);
  const [deleteVehicleModal, setDeleteVehicleModal] = useState(false);
  const [deletedVehicleMMID, setDeletedVehicleMMID] = useState();
  const [existingItemDetails, setExistingItemDetails] = useState({});
  const [addMoreVehiclesModal, setAddMoreVehiclesModal] = useState(false);
  const [isVinInfoValid, setIsVinInfoValid] = useState(false);
  const [vehicleInfoList, setVehicleInfoList] = useState([
    {
      id: "",
      vin: { value: "", errorText: "", error: false, success: "" },
      year: { value: "", errorText: "", error: false, success: "" },
      make: { value: "", errorText: "", error: false, success: "" },
      model: { value: "", errorText: "", error: false, success: "" },
      quantity: { value: "", errorText: "", error: false, success: "" },
      isValid: false,
    },
  ]);
  const [contractItemList, setContractItemList] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const [division, setDivision] = useState({
    value: workTicketInfo.workTicketInfo.division,
    error: false,
    errorText: "Please validate the Division.",
    success: true,
  });
  const [distributionchannel, setDistributionchannel] = useState({
    value: workTicketInfo.workTicketInfo.distributionChannel,
    error: false,
    errorText: "Please validate the Distribution Channel.",
    success: true,
  });
  const [vehicleMMIDDetails, setVehicleMMIDDetails] = useState([]);
  const [isMultiselectAllDeleted, setIsMultiselectAllDeleted] = useState(false);
  const handleClickOutside = () => {
    notification.setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  useEffect(() => {
    if (vehicleData && Object.keys(vehicleData).length) {
      let mmidTableList = vehicleData?.materials.map((records) => {
        let upsellUI = records.upsell == true ? "Yes" : records.upsell == false ? "No" : "--";
        let record = {
          id: generate(),
          mId: records.id,
          materialId: records.materialId,
          itemDescription: records.materialName,
          upsell: records.upsell,
          upsellForUI: upsellUI,
          type: records.vmfType,
          displayType: getFullName(TYPE_DROPDOWN_VALUES, records.vmfType),
          techRefID: records.techRefId,
          contractItem: records.vmfSapName,
          feature: records.vinMaterialFeatures.map((feature) => feature?.featureId),
          validMMID: records.validMMID,
          sapIdVal: records.vmfSapId,
          sapId: records.vmfSapIdVal,
          orderType: records.orderType,
          bundleId: records.bundleId ? records.bundleId : "",
          vinMaterialFeatures: records.vinMaterialFeatures,
          vmfIdList: records.vinMaterialFeatures.map((feature) => feature?.vmfId),
        };
        return record;
      });
      setVehicleMMIDTableList(mmidTableList);
      setVehicleMMIDDetails(mmidTableList);
      handleFields("distributionchannel", vehicleData.distributionChannel);
      handleFields("division", vehicleData.division);
      handleFields("vin", vehicleData.vin, 0, vehicleData.id);
      vehicleInfoList[0].id = vehicleData.id;
      handleFields("year", vehicleData.year, 0);
      handleFields("make", vehicleData.make, 0);
      handleFields("model", vehicleData.model, 0);
      handleFields("installType", getInstallType(vehicleData.installType));
      if ((vehicleData.vin == null || vehicleData.vin == "") && vehicleData.year !== null && vehicleData.year !== "")
        vehicleInfoList[0].quantity = { ...vehicleInfoList[0].quantity, value: 1, error: false, errorText: "", success: true };
    }

    if (vehicleData?.salesOrg) {
      let [salesOrgId, ...salesOrgName] = vehicleData.salesOrg.split(" ");
      let salesOrgValue = salesOrgName ? `(${salesOrgName.join(" ") || ""})` : "";
      salesOrgValue = `${salesOrgId || ""} ${salesOrgValue}`;
      setSalesOrg((prevState) => ({ ...prevState, value: salesOrgValue, error: false, success: true }));
    } else {
      let salesOrgValue = cspDetails.cspDetails?.salesOrgName ? `(${cspDetails.cspDetails?.salesOrgName || ""})` : "";
      salesOrgValue = `${cspDetails.cspDetails?.salesOrgId || ""} ${salesOrgValue}`;
      setSalesOrg((prevState) => ({ ...prevState, value: salesOrgValue, error: false, success: true }));
    }
  }, []);

  useEffect(() => {
    if (
      isVinInfoValid &&
      validateVIN(vehicleInfoList) &&
      address.verified &&
      installType.success &&
      vehicleMMIDTableList.length &&
      salesOrg.success &&
      distributionchannel.success &&
      division.success
    ) {
      setIsSaveBtnDisabled(false);
    } else {
      setIsSaveBtnDisabled(true);
    }
  }, [
    isVinInfoValid,
    address.verified,
    installType.success,
    vehicleMMIDTableList.length,
    salesOrg.success,
    distributionchannel.success,
    division.success,
    vehicleInfoList,
  ]);

  // handle when user deletes a vehicle info list
  function handleVehicleRemoveClick(e, index) {
    const list = [...vehicleInfoList];
    list.splice(index, 1);
    setVehicleInfoList(list);
  }

  // const notify = (content, type) => {
  //   setNotification(<Notification key={generate()} type={type} title={content} fullBleed={true} />);
  // };
  function validateVINList(vehicleInfoList, currentVinList) {
    if (currentVinList.vin.error || currentVinList.year.error || currentVinList.make.error || currentVinList.model.error)
      currentVinList.isValid = false;
    else if (currentVinList.vin.success || (currentVinList.year.success && currentVinList.make.success && currentVinList.model.success))
      currentVinList.isValid = true;
    else if (
      vehicleInfoList.length == 1 &&
      currentVinList.vin.value == "" &&
      currentVinList.year.value == "" &&
      currentVinList.make.value == "" &&
      currentVinList.model.value == ""
    )
      currentVinList.isValid = false;
    else if (currentVinList.vin.value == "" && currentVinList.year.value == "" && currentVinList.make.value == "" && currentVinList.model.value == "")
      currentVinList.isValid = true;
    if (currentVinList.year.success || currentVinList.make.success || currentVinList.model.success) {
      if (currentVinList.year.success && currentVinList.make.success && currentVinList.model.success) currentVinList.isValid = true;
      else currentVinList.isValid = false;
    }
    setVehicleInfoList(vehicleInfoList);
    if (vehicleInfoList && vehicleInfoList.filter((record) => record.isValid === false).length > 0) {
      setIsVinInfoValid(false);
    } else setIsVinInfoValid(true);
  }
  function validateVIN(vinList) {
    let vinValid = true;
    vinList.map((record) => {
      if (record.vin.error || record.year.error || record.make.error || record.model.error) {
        vinValid = false;
        return;
      } else {
        if (record.vin.value == "") {
          if (record.year.value == "" || record.make.value == "" || record.model.value == "") {
            vinValid = false;
            return;
          }
        }
      }
    });
    return vinValid;
  }
  const handleFields = (field, value, index, id = "") => {
    const vinList = [...vehicleInfoList];
    switch (field) {
      case "vin": {
        if (value && value.length) {
          let duplicateVin = vehicleInfoList && vehicleInfoList.filter((record) => record.vin.value.toUpperCase() === value);
          if (duplicateVin.length > 0 || (vehicleData && Object.keys(vehicleData).length && vehicleData.vin == value && vehicleData.vinCount > 1)) {
            vinList[index].vin = { ...vinList[index].vin, value: value, error: true, errorText: "Duplicate VIN", success: false };
          } else if (VIN_REGEX.test(value)) {
            vinList[index].vin = { ...vinList[index].vin, value: value, error: false, errorText: "", success: true };
            vinList[index].quantity = { ...vinList[index].quantity, value: 1, error: false, errorText: "", success: true };
            vinList[index].id = vinList[index]?.id ? vinList[index].id : id;
          } else {
            vinList[index].vin = { ...vinList[index].vin, value: value, error: true, errorText: "Enter a valid VIN", success: false };
          }
        } else {
          vinList[index].vin = { ...vinList[index].vin, value: "", error: false, errorText: "", success: "" };
        }
        validateVINList(vinList, vinList[index]);
        break;
      }
      case "year": {
        value = /[A-Za-z]/.test(value) ? value?.replace(/[^\w]/g, "") : value;
        if (value && value.length) {
          if (vehicleData && Object.keys(vehicleData).length && vehicleData.errorYMM) {
            vinList[index].year = { ...vinList[index].year, value: value, error: true, errorText: "Enter valid year", success: false };
          }
          if (ZIP_REGEX.test(value)) {
            if (parseInt(value) >= 1800 && parseInt(value) <= new Date().getFullYear() + 10) {
              vinList[index].year = { ...vinList[index].year, value: value, error: false, errorText: "", success: true };
            } else {
              vinList[index].year = { ...vinList[index].year, value: value, error: true, errorText: "Enter valid year", success: false };
            }
          } else {
            vinList[index].year = { ...vinList[index].year, value: value, error: true, errorText: "Enter valid year", success: false };
          }
        } else {
          vinList[index].year = { ...vinList[index].year, value: "", error: false, errorText: "", success: "" };
        }
        validateVINList(vinList, vinList[index]);
        break;
      }
      case "make": {
        if (value && value.length) {
          if (MAKE_REGEX.test(value)) {
            vinList[index].make = { ...vinList[index].make, value: value, error: false, errorText: "", success: true };
          } else {
            vinList[index].make = { ...vinList[index].make, value: value, error: true, errorText: "Enter valid make", success: false };
          }
        } else {
          vinList[index].make = { ...vinList[index].make, value: "", error: false, errorText: "", success: "" };
        }
        validateVINList(vinList, vinList[index]);
        break;
      }
      case "model": {
        if (value && value.length) {
          if (MODEL_REGEX.test(value)) {
            vinList[index].model = { ...vinList[index].model, value: value, error: false, errorText: "", success: true };
          } else {
            vinList[index].model = { ...vinList[index].model, value: value, error: true, errorText: "Enter valid model", success: false };
          }
        } else {
          vinList[index].model = { ...vinList[index].model, value: "", error: false, errorText: "", success: "" };
        }
        validateVINList(vinList, vinList[index]);
        break;
      }
      case "installType": {
        value && value.length
          ? setInstallType({ value, error: false, errorText: "Select install type", success: true })
          : setInstallType({ value: "", error: true, errorText: "Select install type", success: false });
        break;
      }
      case "quantity": {
        if (value && value.length) {
          if (vinList[index].vin.value == null || vinList[index].vin.value == "") {
            if (QUANTITY_REGEX.test(value)) {
              vinList[index].quantity = { ...vinList[index].quantity, value: value, error: false, errorText: "", success: true };
            } else {
              vinList[index].quantity = { ...vinList[index].quantity, value: value, error: true, errorText: "Enter valid quantity", success: false };
            }
          }
        } else {
          vinList[index].quantity = { ...vinList[index].quantity, value: "", error: false, errorText: "", success: "" };
        }
        setVehicleInfoList(vinList);
        break;
      }
      case "division": {
        value && value.length
          ? setDivision({ value, error: false, errorText: "Select division", success: true })
          : setDivision({ value: "", error: true, errorText: "Select division", success: false });
        break;
      }
      case "distributionchannel": {
        value && value.length
          ? setDistributionchannel({ value, error: false, errorText: "Select distribution channel", success: true })
          : setDistributionchannel({ value: "", error: true, errorText: "Select distribution channel", success: false });
        break;
      }
    }
  };

  const showActionIcon = (row) => (
    <FlexRow>
      <div onClick={() => showEditItemModal(row)}>
        <img
          style={{ width: "15px", cursor: "pointer", marginRight: "20px" }}
          alt="edit-icon"
          aria-label="Edit Icon"
          src={edit}
          aria-controls="overlay_panel"
        />
      </div>
      <div onClick={() => showVehicleDeleteModal(row)}>
        <Icon
          className="trash-icon"
          aria-label="Delete"
          alt="delete-icon"
          name="trash"
          size="medium"
          color="#000000"
          style={{ cursor: "pointer", outline: "none" }}
        />
      </div>
    </FlexRow>
  );

  const showEditItemModal = (row) => {
    setAddVehicleMMIDModal(true);
    setExistingItemDetails(row);
  };

  const showVehicleDeleteModal = (row) => {
    setDeleteVehicleModal(true);
    setDeletedVehicleMMID(row);
  };

  const handleVehicleMMIDRecordDelete = (rowData) => {
    let tableData = _.cloneDeep(vehicleMMIDTableList).filter((item) => item.id !== rowData.id);
    setVehicleMMIDTableList(tableData);
    handleVehicle("deleted", rowData);
  };
  const handleAddMoreVehicles = () => {
    const tempVehicleArr = [
      {
        vin: { value: "", errorText: "", error: false, success: "" },
        year: { value: "", errorText: "", error: false, success: "" },
        make: { value: "", errorText: "", error: false, success: "" },
        model: { value: "", errorText: "", error: false, success: "" },
        quantity: { value: "", errorText: "", error: false, success: "" },
      },
    ];
    setVehicleInfoList((current) => [...current, ...tempVehicleArr]);
  };
  const handleSaveVehicle = async (addAdditionalAddress = false) => {
    setLoader(true);
    let cspAccDetails = JSON.parse(sessionStorage.getItem("CSPDetails"));
    let vins = vehicleInfoList.map((vehicleInfo) => {
      return {
        vin: vehicleInfo.vin.value,
        year: vehicleInfo.year.value,
        make: vehicleInfo.make.value,
        model: vehicleInfo.model.value,
        quantity: vehicleInfo.quantity.value || 1,
      };
    });
    let materials = vehicleMMIDTableList.map((recordList) => {
      const featArrOfNum = recordList.feature.map((str) => {
        return parseInt(str, 10);
      });
      return {
        mid: recordList.mId,
        materialId: recordList.materialId,
        featureId: featArrOfNum,
        type: recordList.type,
        orderType: recordList.orderType,
        upsell: recordList.upsell,
        techRefId: recordList.techRefID,
        contractSapId: recordList.sapId,
        sapIdVal: recordList.contractId,
        bundleId: recordList?.bundleId ? recordList.bundleId : "",
      };
    });
    let data = {
      vinList: vins,
      registrationNumber: "",
      caseNumber: cspAccDetails?.CASEDetails[0]?.CaseNumber || "",
      accountId: cspDetails.cspDetails.accountId,
      salesOrgId: salesOrg.value.split(" ")[0],
      address1: address.address1.value,
      address2: address.address2.value ?? null,
      city: address.city.value,
      country: address.country.value.code,
      stateCode: address.state.value.erpStateCode,
      zipCode: address.zip.value,
      timezone: address.timezone,
      addressByPass: address.addressByPass,
      locationFound: address.locationFound,
      caseId: cspDetails.cspDetails.caseId,
      cspId: cspDetails.cspDetails.cspId,
      customerName: cspDetails.cspDetails.customerName,
      customerNumber: cspDetails.cspDetails.customerNumber,
      installType: installType.value,
      vztSegment: cspDetails.cspDetails.vztSegment,
      createdBy: `${cspDetails.cspDetails.firstName} ${cspDetails.cspDetails.lastName}`,
      contractAccountId: workTicketInfo.workTicketInfo.contractAccount,
      distributionChannel: distributionchannel.value,
      division: division.value,
      materials: materials,
    };

    let response = await addVehicle(data);
    if (response.status === 200 && response?.data?.message.toLowerCase() === "success") {
      notification.setNotification(<Notification key={generate()} title="Vehicle added successfully" type="success" />);
      setTimeout(() => {
        notification.setNotification(null);
        if (!addAdditionalAddress) {
          redirectToPrevPage();
        } else {
          setVehicleInfoList([
            {
              vin: { value: "", errorText: "", error: false, success: "" },
              year: { value: "", errorText: "", error: false, success: "" },
              make: { value: "", errorText: "", error: false, success: "" },
              model: { value: "", errorText: "", error: false, success: "" },
              quantity: { value: "", errorText: "", error: false, success: "" },
              isValid: false,
            },
          ]);
          setAddress((prevState) => ({
            verified: false,
            addressByPass: null,
            locationFound: null,
            timezone: "",
            historicalAddress: { value: "", error: false, errorText: "", success: false },
            address1: { value: "", error: false, errorText: "Enter valid street address", success: false },
            address2: { value: "", error: false, errorText: "Enter address 2", success: false },
            city: { value: "", error: false, errorText: "Enter city", success: false },
            state: { ...prevState.state, value: null, options: [], error: false, errorText: "Please Select State", success: false },
            zip: { value: "", error: false, errorText: "Enter zip code", success: false },
            country: { ...prevState.country, value: null, error: false, errorText: "Please select country", success: false },
          }));
          setVehicleMMIDTableList([]);
          setInstallType({ value: "", error: false, errorText: "Select install type", success: false });
        }
        setLoader(false);
      }, 3000);
    } else {
      notification.setNotification(
        <Notification key={generate()} title="We are currently experiencing issues, please try again" type="error" fullBleed={true} />
      );
      setLoader(false);
    }
  };
  const handleEditVehicle = async () => {
    setLoader(true);
    let cspAccDetails = JSON.parse(sessionStorage.getItem("CSPDetails"));
    let vins = vehicleInfoList.map((vehicleInfo) => {
      return {
        id: vehicleInfo.id || "",
        vin: vehicleInfo.vin.value,
        year: vehicleInfo.year.value,
        make: vehicleInfo.make.value,
        model: vehicleInfo.model.value,
        quantity: vehicleInfo.quantity.value || 1,
      };
    });
    let vehicleMMIDD = vehicleMMIDDetails;
    let materials = vehicleMMIDD.map((recordList) => {
      let vinMaterialFeaturesList = recordList.vinMaterialFeatures.map((item) => {
        item.actionType = item?.actionType ? item.actionType : "edited";
        return item;
      });

      let formatData = vinMaterialFeaturesList.map((data) => {
        return {
          actionType: data.actionType,
          featureId: typeof data.featureId === "object" ? data.featureId.value : data.featureId,
          vmfId: data.vmfId,
        };
      });

      if (isMultiselectAllDeleted) {
        formatData = vinMaterialFeaturesList.map((data) => {
          return {
            actionType: "deleted",
            featureId: data.featureId.value ? data.featureId.value : data.featureId,
            vmfId: data.vmfId,
          };
        });

        formatData = formatData.filter((data) => data.vmfId !== "");
      }

      const vinMaterialFeaturesListData = formatData.filter((obj, index) => {
        return index === formatData.findIndex((o) => obj.featureId === o.featureId);
      });

      return {
        mid: recordList.mId,
        materialId: recordList.materialId,
        vinMaterialFeatures: vinMaterialFeaturesListData,
        type: recordList.type,
        upsell: recordList.upsell,
        techRefId: recordList?.orderType === "Upsell" && recordList.upsell ? null : recordList.techRefID,
        contractSapId: recordList?.orderType === "Upsell" && recordList.upsell ? null : recordList.sapId,
        sapIdVal:
          recordList?.orderType === "Upsell" && recordList.upsell ? null : recordList.contractId ? recordList.contractId : recordList.sapIdVal,
        orderType: recordList.orderType,
        bundleId: recordList?.bundleId ? recordList.bundleId : "",
      };
    });

    let data = {
      vinList: vins,
      registrationNumber: "",
      caseNumber: cspAccDetails?.CASEDetails[0]?.CaseNumber || "",
      accountId: cspDetails.cspDetails.accountId,
      salesOrgId: salesOrg.value.split(" ")[0],
      address1: address.address1.value,
      address2: address.address2.value ?? null,
      city: address.city.value,
      country: address.country.value.code,
      stateCode: address.state.value.erpStateCode,
      zipCode: address.zip.value,
      timezone: address.timezone,
      addressByPass: address.addressByPass,
      locationFound: address.locationFound,
      caseId: cspDetails.cspDetails.caseId,
      cspId: cspDetails.cspDetails.cspId,
      customerName: cspDetails.cspDetails.customerName,
      customerNumber: cspDetails.cspDetails.customerNumber,
      installType: installType.value,
      vztSegment: cspDetails.cspDetails.vztSegment,
      createdBy: `${cspDetails.cspDetails.firstName} ${cspDetails.cspDetails.lastName}`,
      contractAccountId: workTicketInfo.workTicketInfo.contractAccount,
      distributionChannel: distributionchannel.value,
      division: division.value,
      materials: materials,
    };
    let response = await editVehicle(data);
    if (response.status === 200 && response?.data?.message.toLowerCase() === "success") {
      notification.setNotification(<Notification key={generate()} title="Vehicle added successfully" type="success" />);
      setTimeout(() => {
        notification.setNotification(null);
        redirectToPrevPage();
        setLoader(false);
      }, 3000);
    } else {
      notification.setNotification(
        <Notification key={generate()} title="We are currently experiencing issues, please try again" type="error" fullBleed={true} />
      );
      setLoader(false);
    }
  };

  const handleVehicle = (action, data) => {
    if (vehicleData) {
      let vehicleMMID = vehicleMMIDDetails?.find((record) => record?.id === data?.id);
      if (vehicleMMID && action === "edited") {
        let existingFeatureIdList = new Map();
        let newFeatureIdList = new Map();
        vehicleMMID.vinMaterialFeatures.map((item) => {
          existingFeatureIdList.set(item.featureId, item);
        });
        data.vinMaterialFeatures.map((item) => {
          newFeatureIdList.set(item.featureId, item);
        });
        let newList = [];
        Array.from(existingFeatureIdList.keys()).map((fId) => {
          let updatedFeatureItem = existingFeatureIdList.get(fId);
          if (newFeatureIdList.has(fId)) {
            updatedFeatureItem.vmfId
              ? newList.push({ ...updatedFeatureItem, actionType: "edited" })
              : newList.push({ ...updatedFeatureItem, actionType: "added" });
          } else {
            updatedFeatureItem.vmfId && newList.push({ ...updatedFeatureItem, actionType: "deleted" });
          }
        });
        Array.from(newFeatureIdList.keys()).map((fId) => {
          let updatedFeatureItem = newFeatureIdList.get(fId);
          if (!existingFeatureIdList.has(fId)) {
            newList.push({ ...updatedFeatureItem, actionType: "added" });
          }
        });
        data.vinMaterialFeatures = newList;
        let updatedvehicle = vehicleMMIDDetails.filter((item) => item.id !== data.id);
        setVehicleMMIDDetails([...updatedvehicle, { ...data, actionType: action }]);
      } else if (vehicleMMID && action === "deleted") {
        let updatedvehicle = vehicleMMIDDetails.filter((item) => item.id !== data.id);
        if (data?.vmfIdList?.length) {
          let newVinMaterialFeatures = data?.vinMaterialFeatures.filter((item) => item.vmfId);
          newVinMaterialFeatures.map((item) => {
            item.actionType = action;
            return item;
          });
          data.vinMaterialFeatures = newVinMaterialFeatures;
          setVehicleMMIDDetails([...updatedvehicle, data]);
        } else {
          setVehicleMMIDDetails([...updatedvehicle]);
        }
      } else {
        let newVinMaterialFeatures = data?.vinMaterialFeatures.map((item) => {
          return { ...item, actionType: action };
        });
        data.vinMaterialFeatures = newVinMaterialFeatures;
        setVehicleMMIDDetails([...vehicleMMIDDetails, data]);
      }
    }
  };

  const renderImage = (data, rowData) => {
    let showErrorIcon;
    if (rowData.upsell) {
      showErrorIcon = !rowData.validMMID || !rowData.orderType;
    } else {
      showErrorIcon = !rowData.validMMID || (rowData.upsell === false && !rowData.techRefID) || !rowData.contractItem || !rowData.orderType;
    }

    return (
      <>
        <div style={{ display: "flex", "margin-top": "2px" }}>
          <img alt="Error icon" src={FailedIcon} height="16px" width="16px" css={{ visibility: showErrorIcon ? "visible" : "hidden" }} />
        </div>
      </>
    );
  };

  const handleSaveAndAddClick = () => {
    handleSaveVehicle(true);
  };

  return (
    <>
      <Loader active={isLoading} fullscreen />
      {addVehicleMMIDModal && (
        <AddVehicleMMIDModal
          addVehicleMMIDModal={addVehicleMMIDModal}
          setAddVehicleMMIDModal={setAddVehicleMMIDModal}
          vehicleMMIDTableList={vehicleMMIDTableList}
          setVehicleMMIDTableList={setVehicleMMIDTableList}
          existingItemDetails={existingItemDetails}
          contractItemList={contractItemList}
          setContractItemList={setContractItemList}
          handleVehicle={handleVehicle}
          setIsMultiselectAllDeleted={setIsMultiselectAllDeleted}
        />
      )}
      {deleteVehicleModal && (
        <DeleteVehicleMMIDModal
          deleteVehicleModal={deleteVehicleModal}
          setDeleteVehicleModal={setDeleteVehicleModal}
          deletedVehicleMMID={deletedVehicleMMID}
          handleVehicleMMIDRecordDelete={handleVehicleMMIDRecordDelete}
          handleVehicle={handleVehicle}
        />
      )}
      {addMoreVehiclesModal && (
        <AddMoreVehiclesModal
          addMoreVehiclesModal={addMoreVehiclesModal}
          setAddMoreVehiclesModal={setAddMoreVehiclesModal}
          handleAddMoreVehicles={handleAddMoreVehicles}
        />
      )}

      {/* {notification} */}
      <Header>{!vehicleData ? `Add A Vehicle` : `Edit Details`}</Header>
      <Spacer top="0.5rem" />
      <UltraMediumText css={{ marginTop: "1.25rem" }} eDSFont fontSize="18px" marginBottom="0.7rem">
        Vehicle Information
      </UltraMediumText>
      <SmallText>Enter either your vehicle&apos;s VIN or YMM</SmallText>

      <ModalBody
        css={{
          height: vehicleInfoList.length > 5 && "23rem",
          overflowY: vehicleInfoList.length > 5 && "scroll",
          paddingLeft: "0px !important",
        }}
        id="vehicleModalBody"
      >
        {vehicleInfoList?.length > 0 &&
          vehicleInfoList?.map((x, i) => {
            return (
              <Row key={x.vin} css={{ marginTop: "1.25rem" }} eDSFont fontSize="18px" marginBottom="0rem">
                <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 12 }}>
                  <Row>
                    <CompactCol
                      colSizes={{ mobile: 4, tablet: 12, desktop: i != 0 ? 3 : 4 }}
                      id={i == 0 ? "customColSingleVinCss" : "customColVinCss"}
                    >
                      <CustomInput
                        id="customColCss"
                        width="100%"
                        paddingRight="5px"
                        type="text"
                        label="VIN"
                        value={x?.vin.value ? x.vin.value : ""}
                        errorText={x?.vin.errorText}
                        error={x?.vin.error}
                        success={x?.vin.success}
                        onChange={(e) => handleFields("vin", e.target.value, i)}
                        maxLength={17}
                        css={VINInputCss}
                      />
                    </CompactCol>
                    <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 2 }}>
                      <CustomInput
                        id="customColCss"
                        width="100%"
                        marginBottom="0.6rem"
                        type="text"
                        paddingRight="5px"
                        label="Year"
                        value={x?.year.value ? x.year.value : ""}
                        errorText={x?.year.errorText}
                        error={x?.year.error}
                        success={x?.year.success}
                        onChange={(e) => handleFields("year", e.target.value, i)}
                        maxLength={4}
                        css={ColumnAlignment}
                      />
                    </CompactCol>
                    <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 2 }}>
                      <CustomInput
                        id="customColCss"
                        width="100%"
                        type="text"
                        paddingRight="5px"
                        label="Make"
                        value={x?.make.value ? x.make.value : ""}
                        errorText={x?.make.errorText}
                        error={x?.make.error}
                        success={x?.make.success}
                        onChange={(e) => handleFields("make", e.target.value, i)}
                        css={ColumnAlignment}
                      />
                    </CompactCol>

                    <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 2 }}>
                      <CustomInput
                        id="customColCss"
                        width="100%"
                        paddingRight="5px"
                        type="text"
                        label="Model"
                        value={x?.model.value ? x.model.value : ""}
                        errorText={x?.model.errorText}
                        error={x?.model.error}
                        success={x?.model.success}
                        onChange={(e) => handleFields("model", e.target.value, i)}
                      />
                    </CompactCol>
                    <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 2 }} id="customColQuantityCss">
                      <CustomInput
                        id="customColCss"
                        width="80%"
                        paddingRight="5px"
                        type="number"
                        label="Quantity"
                        value={x?.vin.success ? "1" : x?.quantity.value}
                        errorText={x?.quantity.errorText}
                        error={x?.quantity.error}
                        success={x?.quantity.success}
                        onChange={(e) => handleFields("quantity", e.target.value, i)}
                      />
                    </CompactCol>
                    {i != 0 && (
                      <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 1 }}>
                        <div onClick={(e) => handleVehicleRemoveClick(e, i)}>
                          <Icon
                            width="50%"
                            paddingRight="5px"
                            className="trash-icon"
                            aria-label="delete"
                            alt="delete-icon"
                            name="trash"
                            size="large"
                            color="#000000"
                            style={{ cursor: "pointer", outline: "none", margin: "27px 0px 0px 15px" }}
                          />
                        </div>
                      </CompactCol>
                    )}
                  </Row>
                </CompactCol>
              </Row>
            );
          })}
      </ModalBody>

      <Spacer top="20px" />
      <CustomSLButton
        secondary
        aria-label="Add More Vehicles"
        onClick={() => {
          setAddMoreVehiclesModal(true);
        }}
      >
        Add More Vehicles
      </CustomSLButton>
      <Spacer top="10px" />
      <ValidateAddress
        address={address}
        setAddress={setAddress}
        vehicleData={vehicleData}
        showHistoricalAddressDropdown={!vehicleData}
        setLoader={setLoader}
      />
      <SLGridLayout>
        <SLRowLayout css={{ marginTop: "1.25rem" }}>
          <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
            <SLGridLayout>
              <SLRowLayout css={{ marginTop: "1.25rem" }}>
                <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 4 }}>
                  <CustomInput
                    type="text"
                    label="Sales Org"
                    value={salesOrg.value ? salesOrg.value : ""}
                    errorText={salesOrg.errorText}
                    error={salesOrg.error}
                    success={salesOrg.success}
                    disabled
                    paddingRight="5px"
                  />
                </CompactCol>
                <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 4 }}>
                  <CustomDropdown
                    label="Division"
                    value={division.value ? division.value : "Please select"}
                    errorText={division.errorText}
                    error={division.error}
                    success={division.success}
                    onChange={(e) => handleFields("division", e.target.value)}
                    marginBottom="10px"
                    paddingRight="5px"
                  >
                    <option disabled>Please select</option>
                    {DIVISION_DROPDOWN_VALUES.map((record) => (
                      <option key={record.code} value={record.code}>
                        {`${record.code} - ${record.description}`}
                      </option>
                    ))}
                  </CustomDropdown>
                </CompactCol>
                <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 4 }}>
                  <CustomDropdown
                    label="Distribution Channel"
                    value={distributionchannel.value ? distributionchannel.value : "Please select"}
                    errorText={distributionchannel.errorText}
                    error={distributionchannel.error}
                    success={distributionchannel.success}
                    onChange={(e) => handleFields("distributionchannel", e.target.value)}
                    marginBottom="10px"
                    paddingRight="5px"
                  >
                    <option disabled>Please select</option>
                    {DISTRIBUTION_CHANNEL_DROPDOWN_VALUES.map((record) => (
                      <option key={record.code} value={record.code}>
                        {`${record.code} - ${record.description}`}
                      </option>
                    ))}
                  </CustomDropdown>
                </CompactCol>
              </SLRowLayout>
            </SLGridLayout>
          </CompactCol>
        </SLRowLayout>
      </SLGridLayout>
      <Spacer bottom="10px" />
      <SLGridLayout>
        <SLRowLayout>
          <UltraMediumText tabIndex={0} css={{ marginTop: "1.25rem" }} eDSFont fontSize="18px" marginBottom="0.7rem">
            Install Type
          </UltraMediumText>
        </SLRowLayout>
        <SLRowLayout css={{ marginTop: "1.25rem" }}>
          <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
            <CustomDropdown
              width="280px"
              label="Install Type"
              errorText={installType.errorText}
              error={installType.error}
              value={installType.value ? installType.value : "Please select install type"}
              onChange={(e) => handleFields("installType", e.target.value)}
            >
              <option disabled>Please select install type</option>
              <option>CMI</option>
              <option>VMI</option>
            </CustomDropdown>
          </CompactCol>
        </SLRowLayout>
      </SLGridLayout>
      <UltraMediumText tabIndex={0} css={{ marginTop: "1.25rem" }} eDSFont fontSize="18px" marginBottom="0rem">
        Item
      </UltraMediumText>
      {vehicleMMIDTableList.some((material) => !material.validMMID) && (
        <MMIDErrorNotification key={generate()} type="error" title={<SmallText bold>Please provide valid MMID</SmallText>} hideCloseButton={true} />
      )}
      <div tabIndex={0} aria-label="table">
        <GridComponent
          isExportEnabled={false}
          noDataMessage="No data available to display"
          rows={vehicleMMIDTableList}
          showActionIcon={showActionIcon}
          columns={ADD_VEHICLE_MMID_COL}
          hideGlobalFilter={true}
          paginator={false}
          whiteSpace="normal"
          renderDataWithImage={renderImage}
        />
      </div>
      <Spacer top="10px" />
      <CustomSLButton
        secondary
        aria-label="Add Item"
        onClick={() => {
          setExistingItemDetails("");
          setAddVehicleMMIDModal(true);
        }}
      >
        Add Item
      </CustomSLButton>
      <Spacer top="10px" />
      <FlexRow css={{ marginTop: "2rem" }}>
        <SLButton
          aria-label="Save"
          disabled={isSaveBtnDisabled}
          onClick={() => {
            vehicleData ? handleEditVehicle() : handleSaveVehicle();
          }}
        >
          Save
        </SLButton>
        {!vehicleData && (
          <SLButton aria-label="Save & Add" onClick={() => handleSaveAndAddClick()} secondary disabled={isSaveBtnDisabled}>
            Save & Add
          </SLButton>
        )}
        <SLButton
          secondary
          aria-label="Cancel"
          onClick={() => {
            redirectToPrevPage();
          }}
        >
          Cancel
        </SLButton>
      </FlexRow>
    </>
  );
}

export const AddAVehicleMetadata = {
  name: "Add A Vehicle",
  id: "add_a_vehicle",
  component: lazy(() => import("./AddAVehicle")),
  route: "/add_a_vehicle",
};

export const EditDetailsMetadata = {
  name: "Edit Details",
  id: "edit_details",
  component: lazy(() => import("./AddAVehicle")),
  route: "/add_a_vehicle",
};
