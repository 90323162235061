import React, { useEffect, useState, useContext, useMemo } from "react";
import { CompactCol, FlexColumn, SmallText, Spacer, UltraMediumText } from "../../components/core/utility";
import { Col, Grid, Row } from "@vds/grids";
import { SLGridLayout, SLRowLayout } from "../../components/core/Utility/index";
import styled from "@emotion/styled";
import DocumentFlowTable from "./DocumentFlowTable";
import MMIDLevelDocumentTable from "./MMIDLevelDocumentTable";
import { MaterialSOInfoTable } from "./MaterialSOInfoTable";
import moment from "moment";
import { C4SContext } from "../../context/c4sContext";

const TrackingColumn = styled(Col)`
  padding: 0.5rem;
  flex-basis: 14%;
  max-width: 14%;
  min-width: 170px;
`;
const ColumnOne = styled(CompactCol)`
  min-width: 299px;
`;

const ColumnTwo = styled(CompactCol)`
  min-width: 302px;
  flex-basis: 23%;
  max-width: 23%;
`;

const ColumnThree = styled(CompactCol)`
  flex-basis: 21%;
  max-width: 23%;
  @media screen and (min-width: 1232px) {
    flex-basis: 23%;
  }
`;
const TableWrapper = styled.div`
  width: 80%;
  margin: auto;
`;
export const MMIDFlyOutView = ({ data, vehicleInfoList, workTicketData, workticketId }) => {
  const [trackingData, setTrackingData] = useState([]);
  const [documentFlowData, setDocumentFlowData] = useState();
  const [MMIDLevelDocumentData, setMMIDLevelDocumentData] = useState();
  const [bundleId, setBundleId] = useState([]);
  const [contractedPrice, setContractedPrice] = useState();
  const { isSourceCSP } = useContext(C4SContext);
  let cspCaseData = JSON.parse(sessionStorage.getItem("CSPDetails")),
    erpProviderNumber = "--",
    customerPONumber = "--";
  if (isSourceCSP.isSourceCSP) {
    erpProviderNumber = cspCaseData.CSP?.filter((cspData) => cspData.DefaultCSP === "1")[0]?.Provider_Order ?? "--";
    customerPONumber = cspCaseData?.CSP_CUstomer_PO_Number ?? "--";
  }
  useEffect(() => {
    let trackingListData = [
      {
        trackingId: "--",
        esn: "--",
      },
      {
        trackingId: "--",
        esn: "--",
      },
    ];
    let documentData = {
      salesOrder: [1],
      deliveryNumber: [0, 567, 5678],
      purchaseOrder: [1],
      returnDelivery: [0],
    };
    let documentFlowMMIDLevelData = {
      pending: [0],
      ordered: [1],
      shipped: [0],
      scheduled: [1],
      unscheduled: [1],
      installed: [0],
      remaining: [0],
      selfInstalled: [0],
      shippedAccessories: [0],
      returnHardware: [0],
      returnAccessory: [0],
      emptyColumn: "",
    };
    setMMIDLevelDocumentData(documentFlowMMIDLevelData);
    setDocumentFlowData(documentData);
    setTrackingData(trackingListData);
    let bundleIdData = [];
    // if (workticketId.startsWith("W")) {
    //   vehicleInfoList.map((record) => {
    //     record?.materialList.map((material) => {
    //       if (material.materialId === data.MMID) {
    //         bundleIdData.push(material.bundleId);
    //       }
    //     });
    //   });
    // } else {
    workTicketData.map((material) => {
      if (material.MMID === data.MMID) {
        bundleIdData.push(material.bundleId);
      }
    });
    // }
    setBundleId([...new Set(bundleIdData.filter((record) => record))]);

    let chargeAmt = 0;
    let currency = "";
    workTicketData.map((material) => {
      //This is for C4S tickects for hardware materials, and to avoide the service materials staring with 13
      if (material.MMID.startsWith("11")) {
        if (material.MMID === data.MMID) {
          if (!currency) currency = material.materialChargeCurrency;
          chargeAmt = chargeAmt + parseFloat(material.customerMaterialCharge) * material.totalQty;
        }
      }
    });
    let customerMaterialCharge = chargeAmt !== "" && chargeAmt !== undefined && chargeAmt !== null && !isNaN(chargeAmt) ? chargeAmt.toFixed(2) : "--";
    let materialChargeCurrency = `${currency || ""} ${customerMaterialCharge}`;
    setContractedPrice(materialChargeCurrency);
  }, []);

  const techRefIdsArr = useMemo(() => {
    let techRefIds = [];
    // if (workticketId.startsWith("W")) {
    //   vehicleInfoList.map((record) => {
    //     record.materialList.map((material) => {
    //       if (material.materialId === data.MMID && material.techRefId && !techRefIds.includes(material.techRefId)) {
    //         techRefIds = [...techRefIds, material.techRefId];
    //       }
    //     });
    //   });
    // } else {
    workTicketData.map((material) => {
      if (material.MMID === data.MMID && material.techRefId && !techRefIds.includes(material.techRefId)) {
        techRefIds = [...techRefIds, material.techRefId];
      }
    });
    // }
    return techRefIds;
  }, []);

  return (
    <>
      <FlexColumn css={{ width: "100%" }}>
        <SLGridLayout role="grid" style={{ marginTop: "1rem", marginBottom: "1.5rem" }}>
          <SLRowLayout>
            <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
              <TableWrapper>
                <DocumentFlowTable data={data} />
              </TableWrapper>
            </CompactCol>
          </SLRowLayout>
          <SLRowLayout css={{ width: "89.5%", margin: "auto", justifyContent: "space-between !important" }}>
            <div css={{ width: "48%" }}>
              <MaterialSOInfoTable data={data.trackingNumbers} title="Tracking Number" property="trackingNumber" />
            </div>
            <div css={{ width: "48%" }}>
              <MaterialSOInfoTable data={data.esns} title="ESN" property="esn" />
            </div>
          </SLRowLayout>
          <SLRowLayout css={{ width: "89.5%", margin: "auto", justifyContent: "space-between !important" }}>
            <div css={{ width: "48%" }}>
              <MaterialSOInfoTable data={data.carriers} title="Carrier" property="carrier" />
            </div>
            <div css={{ width: "48%" }}>
              <MaterialSOInfoTable data={data.trackingUrls} title="Tracking URL" property="trackingUrl" />
            </div>
          </SLRowLayout>
          <SLRowLayout>
            <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
              <SLGridLayout>
                <SLRowLayout>
                  <ColumnOne colSizes={{ mobile: 1, tablet: 3, desktop: 3 }}>
                    <SmallText css={{ marginTop: "1.5rem" }}> Tech reference ID</SmallText>
                    <UltraMediumText fontWeight="normal" marginTop="12px !important" style={{ textWrap: "balance" }}>
                      {/* {techRefIdsArr.length ? techRefIdsArr.join(",") : "--"} */}
                      {/* VZC42148-1709 */}
                      {data.techRefId ? data.techRefId : "--"}
                    </UltraMediumText>
                  </ColumnOne>
                  <ColumnTwo colSizes={{ mobile: 1, tablet: 3, desktop: 3 }}>
                    <SmallText css={{ marginTop: "1.5rem" }}> Delivery Block </SmallText>
                    <UltraMediumText fontWeight="normal" marginTop="12px !important">
                      {data.deliveryBlock ? data.deliveryBlock : "--"}
                    </UltraMediumText>
                  </ColumnTwo>
                  <ColumnThree colSizes={{ mobile: 1, tablet: 3, desktop: 3 }}>
                    <SmallText css={{ marginTop: "1.5rem" }}>Contract </SmallText>
                    <UltraMediumText fontWeight="normal" marginTop="12px !important">
                      {erpProviderNumber}
                    </UltraMediumText>
                  </ColumnThree>
                  <CompactCol colSizes={{ mobile: 1, tablet: 3, desktop: 3 }}>
                    <SmallText css={{ marginTop: "1.5rem" }}>ATP Relevant</SmallText>
                    <UltraMediumText fontWeight="normal" marginTop="12px !important">
                      Yes
                    </UltraMediumText>
                  </CompactCol>
                </SLRowLayout>
              </SLGridLayout>
            </CompactCol>
          </SLRowLayout>
          <SLRowLayout>
            <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
              <SLGridLayout>
                <SLRowLayout>
                  <ColumnOne colSizes={{ mobile: 1, tablet: 3, desktop: 3 }}>
                    <SmallText css={{ marginTop: "1.5rem" }}> PGI Date </SmallText>
                    <UltraMediumText fontWeight="normal" marginTop="12px !important">
                      {data.pgiDate ? moment(data.pgiDate, "YYYYMMDD").format("MMM D, yyyy") : `--`}
                    </UltraMediumText>
                  </ColumnOne>
                  <ColumnTwo colSizes={{ mobile: 1, tablet: 3, desktop: 3 }}>
                    <SmallText css={{ marginTop: "1.5rem" }}> PGI Quantity</SmallText>
                    <UltraMediumText fontWeight="normal" marginTop="12px !important">
                      {data.pgiQty ? data.pgiQty : "--"}
                    </UltraMediumText>
                  </ColumnTwo>
                  <ColumnThree colSizes={{ mobile: 1, tablet: 3, desktop: 3 }}>
                    <SmallText css={{ marginTop: "1.5rem" }}> Contracted Price</SmallText>
                    <UltraMediumText fontWeight="normal" marginTop="12px !important">
                      {contractedPrice ?? "--"}
                    </UltraMediumText>
                  </ColumnThree>
                  <CompactCol colSizes={{ mobile: 1, tablet: 3, desktop: 3 }}>
                    <SmallText css={{ marginTop: "1.5rem" }}> Customer PO Number </SmallText>
                    <UltraMediumText fontWeight="normal" marginTop="12px !important">
                      {customerPONumber}
                    </UltraMediumText>
                  </CompactCol>
                </SLRowLayout>
              </SLGridLayout>
            </CompactCol>
          </SLRowLayout>
          <SLRowLayout>
            <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
              <SLGridLayout>
                <SLRowLayout>
                  <ColumnOne colSizes={{ mobile: 1, tablet: 3, desktop: 3 }}>
                    <SmallText css={{ marginTop: "1.5rem" }}> Bundle Name </SmallText>
                    <UltraMediumText fontWeight="normal" marginTop="12px !important">
                      {bundleId.length ? bundleId.map((item, idx) => item && (idx === bundleId.length - 1 ? item : `${item}, `)) : "--"}
                    </UltraMediumText>
                  </ColumnOne>
                  <ColumnTwo colSizes={{ mobile: 1, tablet: 3, desktop: 3 }}>
                    <SmallText css={{ marginTop: "1.5rem" }}> Order Type </SmallText>
                    <UltraMediumText fontWeight="normal" marginTop="12px !important">
                      {data.orderType ? data.orderType : "--"}
                    </UltraMediumText>
                  </ColumnTwo>
                  <ColumnThree colSizes={{ mobile: 1, tablet: 3, desktop: 3 }}>
                    <SmallText css={{ marginTop: "1.5rem" }}> Contract Override </SmallText>
                    <UltraMediumText fontWeight="normal" marginTop="12px !important">
                      {data.contractOverride ? data.contractOverride : "--"}
                    </UltraMediumText>
                  </ColumnThree>
                </SLRowLayout>
              </SLGridLayout>
            </CompactCol>
          </SLRowLayout>
        </SLGridLayout>
      </FlexColumn>
    </>
  );
};

export default MMIDFlyOutView;
