import { Grid, Row } from "@vds/grids";
import { SLGridLayout, SLRowLayout } from "../../components/core/Utility/index";
import { Icon } from "@vds/icons";
import { Input, TextArea } from "@vds/inputs";
import { css } from "@emotion/react";
import React, { lazy, useContext, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { ATP_SUMMARY_CHECK_COL } from "../../constants/ATPSummaryCheckConstants";
import { Header } from "../../components/core/Controls";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { CompactCol, CustomSLButton, FlexRow, LargeText, SmallText, Spacer } from "../../components/core/utility";
import { EMAIL_REGEX_C360, NAME_REGEX_ATP, PHONE_REGEX } from "../../constants/C4SValidationConstants";
import { redirectToPrevPage, switchTab } from "./utils";
import { C4SContext } from "../../context/c4sContext";
import { createWorkticket } from "../../services/C4S Decommission - Replacement/createWorkticket";
import CreateWorkTicketModal from "./CreateWorkTicketModal";
import { Loader } from "@vds/loaders";
import { Notification } from "@vds/notifications";
import { generate } from "shortid";
import DeleteMMIDModal from "./DeleteMMIDModal";
import _, { groupBy, uniq } from "lodash";
import { updateNewrelicStats } from "../../../utility/newrelic";
import moment from "moment";
import { InputTextarea } from "primereact/inputtextarea";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ATPCheck } from "../../services/C4S Decommission - Replacement/ATPCheck";
import { TextLink } from "@vds/buttons";
import { CustomDropdown } from "./AddAVehicle";
import { quantityTransaction } from "../../services/C4S Decommission - Replacement/quantityTransaction";
import { deleteVehicle } from "../../services/C4S Decommission - Replacement/deleteVehicle";
import { workticketUpdate } from "../../services/C4S Decommission - Replacement/workticketUpdate ";
import { salesOrgBasedCurrency } from "../../constants/PartialCompletionReasons";
import useOutsideClick from "../../customHook/use-outside-click";
import { validateItemQuantity } from "../../services/C4S Decommission - Replacement/validateItemQuantity";
import { ValidateAddress } from "./ValidateAddress";

const CustomInput = styled(Input)`
  max-width: 55rem;
  padding-bottom: 0.6rem;
  height: auto;
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
  & label {
    & p {
      font-size: 12px;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
    }
  }
  & input {
    font-size: 12px;
    height: ${(props) => (props.height ? props.height : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      border-top: 1px solid #dfe0e0;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
  & span {
    width: ${(props) => (props.width ? props.width : "")};
    & span {
      & span {
        max-width: inherit;
        & div:not(:first-child) {
          & svg {
            display: none; // hide success tick icon
          }
        }
      }
    }
  }
`;

const Label = styled.label`
  font-family: "Verizon-NHG-eTX";
  font-size: 12px;
  margin-top: 10px;
  color: #000000;
`;
export const AccordionWrapper = styled.div`
  padding-top: 2.5rem;
  .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link,
  .p-accordion .p-accordion-tab .p-accordion-header:hover .p-accordion-header-link,
  .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    color: #000000;
    border: none;
    padding: 0.5rem;
    background: #f6f6f6;
    box-shadow: unset;
    border-radius: unset;
  }

  .p-accordion .p-accordion-content {
    padding: 0rem;
    border: none;
  }
  .p-accordion-header-text {
    font-weight: 700;
    font-family: Verizon-NHG-eDS;
  }
`;

const AccordionStyle = styled.div`
  padding-top: 2.5rem;
  .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link,
  .p-accordion .p-accordion-tab .p-accordion-header:hover .p-accordion-header-link,
  .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    color: #000000;
    border: none;
    padding: 0.5rem;
    background: #f6f6f6;
    box-shadow: unset;
    border-radius: unset;
  }

  .p-accordion .p-accordion-content {
    padding: 0rem;
    border: none;
  }
  .p-accordion-header-text {
    font-weight: 700;
    font-family: Verizon-NHG-eDS;
  }
  // .pi-chevron-down:before {
  //   content: "-";
  //   font-size: 36px;
  //   font-weight: 1000;
  //   position: absolute;
  //   right: 5px;
  //   top: -10px;
  // }
  // .pi-chevron-up:before {
  //   content: "+";
  //   font-size: 24px;
  //   font-weight: 1000;
  //   position: absolute;
  //   right: 0;
  //   top: 3px;
  // }
`;

const InfoNotification = styled(Notification)`
  & div {
    background-color: rgb(0, 119, 180) !important;
  }
`;

export const TextAreaWrapper = styled.div`
  width: 100%;
  & label {
    & p {
      & span {
        display: none;
      }
    }
  }
  & span {
    & div {
      & div {
        & mark {
          background-color: transparent !important;
        }
      }
    }
  }
`;

export default function ATPSummaryCheck({ data, requestedDeliveryDate, workticketUpdateData = {} }) {
  const { cspDetails, notification, workTicketInfo, isSourceCSP, quantityPayload } = useContext(C4SContext);
  const cspSessionData = JSON.parse(sessionStorage.getItem("CSPDetails"));
  const salesOrgId = cspSessionData?.CSP.length ? cspSessionData?.CSP.filter((csp) => csp.DefaultCSP === "1")[0].Opp_Sales_Org : "";
  const providerOrder = cspSessionData?.CSP.length ? cspSessionData?.CSP.filter((csp) => csp.DefaultCSP === "1")[0].Provider_Order : "";
  const [contactName, setContactName] = useState({
    value: "",
    error: false,
    errorText: "",
    success: false,
  });
  const [email, setEmail] = useState({
    value: "",
    error: false,
    errorText: "",
    success: false,
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: "",
    error: false,
    errorText: "",
    success: false,
  });
  const totalLength = data.length;
  const [currentATPRecordIndex, setCurrentATPRecordIndex] = useState(0);
  const [address, setAddress] = useState("");
  const [newAddress, setNewAddress] = useState({
    verified: false,
    addressByPass: null,
    locationFound: null,
    timezone: "",
    historicalAddress: { value: "", error: false, errorText: "", success: false },
    address1: {
      value: Array.isArray(data[0]?.shippingAddress) ? data[0].shippingAddress[0].addressOne : "",
      error: false,
      errorText: "Enter valid street address",
      success: false,
    },
    address2: {
      value: Array.isArray(data[0]?.shippingAddress) ? data[0].shippingAddress[0].addressTwo : "",
      error: false,
      errorText: "Enter address 2",
      success: false,
    },
    city: {
      value: Array.isArray(data[0]?.shippingAddress) ? data[0].shippingAddress[0].city : "",
      error: false,
      errorText: "Enter city",
      success: false,
    },
    country: {
      value: Array.isArray(data[0]?.shippingAddress) ? data[0].shippingAddress[0].country : "",
      options: [],
      error: false,
      errorText: "Please select country",
      success: false,
    },
    state: {
      value: Array.isArray(data[0]?.shippingAddress) ? data[0].shippingAddress[0].stateCode : "",
      options: [],
      allStateOptions: null,
      error: false,
      errorText: "Please select state",
      success: false,
    },
    zip: {
      value: Array.isArray(data[0]?.shippingAddress) ? data[0].shippingAddress[0].zipCode : "",
      error: false,
      errorText: "Enter zip code",
      success: false,
    },
  });

  const [soldAddress, setSoldAddress] = useState("");
  const [billAddress, setBillAddress] = useState("");
  const [payerAddress, setPayerAddress] = useState("");
  const [notesToWareHouse, setNotesToWareHouse] = useState("");
  const [installType, setInstallType] = useState("");
  const [salesOrg, setSalesOrg] = useState("");
  const [isCreateWorkTicketBtnDisabled, setIsCreateWorkTicketBtnDisabled] = useState(true);
  const [materialsList, setMaterialsList] = useState([]);
  const [VINRecordList, setVINRecordList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [createWorkTicketModal, setCreateWorkTicketModal] = useState(false);
  const [deleteMMIDModal, setDeleteMMIDModal] = useState(false);
  const [materialToBeDeleted, setMaterialToBeDeleted] = useState([]);
  const [vehicleInfo, setVehicleInfo] = useState("");
  let contractAccountNumberArr = JSON.parse(sessionStorage.getItem("CSPDetails"))?.CONTRACT_ACCOUNT_ID || [];
  const [contractAccountNumber, setContractAccountNumber] = useState(contractAccountNumberArr.filter(Boolean));
  const [selectedContractAccountNumber, setSelectedContractAccountNumber] = useState({
    value: "",
    error: false,
    errorText: "",
    success: false,
  });
  const [spiltAddress, setSplitAddress] = useState({ address1: "", address2: "", city: "", state: "", country: "", zip: "" });
  const [oldAddress] = useState({
    addressOne: Array.isArray(data[0]?.shippingAddress) ? data[0].shippingAddress[0].addressOne : "",
    addressTwo: Array.isArray(data[0]?.shippingAddress) ? data[0].shippingAddress[0].addressTwo : "",
    city: Array.isArray(data[0]?.shippingAddress) ? data[0].shippingAddress[0].city : "",
    stateCode: Array.isArray(data[0]?.shippingAddress) ? data[0].shippingAddress[0].stateCode : "",
    country: Array.isArray(data[0]?.shippingAddress) ? data[0].shippingAddress[0].country : "",
    zipCode: Array.isArray(data[0]?.shippingAddress) ? data[0].shippingAddress[0].zipCode : "",
    addressByPass: null,
    locationFound: true,
    id: Array.isArray(data[0]?.shippingAddress) ? data[0].shippingAddress[0].id : "",
  });

  const [originalDONumber, setOriginalDONumber] = useState("");
  const [ERPSubStatus, setERPSubStatus] = useState("");
  const [lostInTransit, setLostInTransit] = useState(false);
  const [defaultExchangeType, setDefaultExchangeType] = useState([]);
  let quantityInfoArr = [];
  const handleClickOutside = () => {
    notification.setNotification(null);
  };

  const [qtyValPayload, setQtyValPayload] = useState({});

  const clickRef = useOutsideClick(handleClickOutside);
  useEffect(() => {
    if (cspSessionData?.source === "CSP") {
      let cspDetails = cspSessionData?.CSP[0];
      handleFields("name", cspDetails.CSPContactName);
      handleFields("email", cspDetails.CSPContactEmail);
      handleFields("phone", cspDetails.CSPContactPhone);
    } else {
      let caseDetails = cspSessionData?.CASEDetails[0];
      handleFields("name", caseDetails.CaseContactName);
      handleFields("email", caseDetails.CaseContactEmail);
      handleFields("phone", caseDetails.CaseContactPhone);
    }
    handleVINRecordIndex();
  }, [currentATPRecordIndex]);

  useEffect(() => {
    if (quantityPayload?.quantityPayload?.transaction?.length > 0) {
      let currentMaterial = Object.values(data[currentATPRecordIndex])[0]?.map((rec) => rec?.materials);
      let currentMaterialData = currentMaterial[0]?.map((mrtdata) => {
        let sfdcQtyQtyVal = quantityPayload?.quantityPayload?.transaction?.filter(
          (sfdqty) => sfdqty?.materialId === mrtdata?.materialId && sfdqty?.techRefId === mrtdata?.techRefId
        );
        return {
          sfdcQty: sfdcQtyQtyVal[0]?.sfdcQty,
          sapId: mrtdata?.vmfSapIdVal || mrtdata?.sapIdVal || null,
          orderedQty: mrtdata?.orderQty || 1,
          upsell: mrtdata?.upsell,
          techRefId: mrtdata?.techRefId,
          materialId: mrtdata?.materialId,
          processingCode: mrtdata?.vmfType || mrtdata?.type,
        };
      });
      let VZCSegement = cspDetails?.cspDetails?.cspData?.data?.data?.VZCSegement.split(" ");
      let government = VZCSegement[0];
      let product = cspDetails?.cspDetails?.cspData?.data?.data?.Product?.map((prod) => {
        return prod?.SAPProductID;
      });
      let qtyPayloadData = {
        cspId: quantityPayload?.quantityPayload?.cspId,
        createdBy: quantityPayload?.quantityPayload?.createdBy,
        crmReferenceId: quantityPayload?.quantityPayload?.crmReferenceId,
        isGovernment: government.toLowerCase() === "government" ? true : false,
        product,
        transaction: currentMaterialData,
      };
      setQtyValPayload(qtyPayloadData);
    }
  }, [materialsList]);

  const quantityValidationCheck = async () => {
    notification.setNotification(null);
    setIsLoading(true);
    let qtyPayload = totalLength !== 1 ? qtyValPayload : quantityPayload.quantityPayload;
    let response = await validateItemQuantity(qtyPayload);
    if (response?.status === 200 && response?.data?.data) {
      let qtyValidationResp = response?.data?.data;
      let qtyExceededMmIds = qtyValidationResp?.validationList?.filter((material) => material.valid === false);
      let qtyExceededMmIdsList = uniq(qtyExceededMmIds?.map((material) => material.materialId))?.join();
      if (qtyExceededMmIdsList.length > 0) {
        notification.setNotification(
          <Notification
            key={generate()}
            type="error"
            title={"Quantity exceeds contract subscription for the selected item(s) with MMID (" + qtyExceededMmIdsList + ")"}
            fullBleed={true}
          />
        );
        setIsCreateWorkTicketBtnDisabled(true);
        setIsLoading(false);
      } else {
        handleWorkTicketCreation();
      }
    } else {
      notification.setNotification(
        <Notification key={generate()} type="error" title="We are currently experiencing issues, please try again" fullBleed={true} />
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (cspSessionData?.source === "CSP") {
      if (
        contactName.success &&
        email.success &&
        phoneNumber.success &&
        materialsList.length &&
        (selectedContractAccountNumber.success || contractAccountNumber?.length === 1) &&
        notesToWareHouse.length <= 255
      ) {
        setIsCreateWorkTicketBtnDisabled(false);
      } else {
        if (contractAccountNumber.length === 1) {
          if (contactName.success && email.success && phoneNumber.success && materialsList.length && notesToWareHouse.length <= 255) {
            if (contractAccountNumber[0] !== null) setIsCreateWorkTicketBtnDisabled(false);
          } else {
            setIsCreateWorkTicketBtnDisabled(true);
          }
        } else {
          setIsCreateWorkTicketBtnDisabled(true);
        }
      }
    } else {
      if (
        contactName.success &&
        email.success &&
        phoneNumber.success &&
        materialsList.length &&
        (selectedContractAccountNumber.success || contractAccountNumber?.length === 1) &&
        notesToWareHouse.length <= 255
      ) {
        setIsCreateWorkTicketBtnDisabled(false);
      } else if (lostInTransit) {
        if (
          contactName.success &&
          email.success &&
          phoneNumber.success &&
          materialsList.length &&
          contractAccountNumber.length &&
          contractAccountNumber[0] &&
          notesToWareHouse.length <= 255
        ) {
          setIsCreateWorkTicketBtnDisabled(false);
        } else {
          setIsCreateWorkTicketBtnDisabled(true);
        }
      } else {
        setIsCreateWorkTicketBtnDisabled(true);
      }
    }
  }, [
    contactName.success,
    email.success,
    phoneNumber.success,
    selectedContractAccountNumber.success,
    contractAccountNumber,
    notesToWareHouse,
    materialsList,
  ]);

  const handleVINRecordIndex = () => {
    let materialsArray = [];
    let VINArray = [];
    let records = _.cloneDeep(data[currentATPRecordIndex]);
    let materialLineIDMapping = new Map();
    let itemNo = 0;
    Object.values(records).map((record) => {
      record.map((material) => {
        setSplitAddress({
          address1: material.addressOne,
          address2: material.addressTwo,
          city: material.city,
          state: material.stateCode,
          country: material.country,
          zip: material.zipCode,
        });
        material.materials.map((item) => {
          let key = `${item.materialId} ${item.techRefId}`;
          if (materialLineIDMapping.has(key)) {
            item.materialLineId = materialLineIDMapping.get(key);
          } else {
            itemNo += 10;
            let formattedItemNo = String(itemNo)?.padStart(6, "0");
            materialLineIDMapping.set(key, formattedItemNo);
            item.materialLineId = formattedItemNo;
          }
          return item;
        });
        material?.lostInTransit ? setLostInTransit(true) : setLostInTransit(false);
        material?.lostInTransit && setContractAccountNumber(material?.contractAccountId ? [material?.contractAccountId] : []);
        material?.originalDONumber && setOriginalDONumber(material?.originalDONumber);
        material?.defaultExchangeType ? setDefaultExchangeType(material?.defaultExchangeType) : setDefaultExchangeType([]);
        material?.subStatus && setERPSubStatus(material?.subStatus);
        VINArray.push(material);
        setVINRecordList((prevState) => [...prevState, material]);
        setAddress(material.shippingAddress);
        setInstallType(material.installType);
        setSalesOrg(material.salesOrg ? material.salesOrg : `${cspDetails.cspDetails?.salesOrgId} ${cspDetails.cspDetails?.salesOrgName}`);
        materialsArray.push(...material.materials);
      });
    });
    setVehicleInfo(
      Object.values(data[currentATPRecordIndex])
        .map((record) =>
          record
            .filter((item) => item.vin != null || item?.ymm != null)
            .map((item) => {
              return item?.vin ?? item?.ymm.replaceAll(", ", " ");
            })
        )[0]
        .filter((record) => record)
    );
    let MMIDGrouped = groupedByMMID(materialsArray);
    handleATPCheck(MMIDGrouped, VINArray);
    //setMaterialsList(MMIDGrouped);
  };

  const groupedByMMID = (mmidList) => {
    return [
      ...mmidList
        .reduce((idx, materials) => {
          !idx.has(materials.materialId) && idx.set(materials.materialId, { ...materials, totalQty: 0 });
          let material = idx.get(materials.materialId);
          material.totalQty += materials?.orderQty ? materials.orderQty : 1;
          return idx;
        }, new Map())
        .values(),
    ];
  };

  const handleATPCheck = async (MMIDList, VINList) => {
    notification.setNotification(null);
    setIsLoading(true);
    updateNewrelicStats();
    let itemNo = 0;
    let itemsList = MMIDList.map((mmid) => {
      itemNo += 10;
      return {
        itemNo: itemNo,
        itemCode: mmid.materialId,
        itemDescription: mmid.itemDesc ? mmid.itemDesc : mmid.materialName,
        orderQuantity: mmid.totalQty,
        unitOfMeasurement: "EA",
        requestedDeliveryDate: moment(requestedDeliveryDate).format("YYYYMMDD"),
        techRefId: mmid.techRefId,
        type: mmid.vmfType ? mmid.vmfType : mmid.type,
      };
    });
    let data = {
      shipToParty: cspSessionData?.ERP_Partner_Number,
      salesOrganization: salesOrgId ? salesOrgId : VINList[0]?.salesOrg?.split(" ")[0] ? VINList[0]?.salesOrg?.split(" ")[0] : "",
      distributionChannel: VINList[0]?.distributionChannel,
      division: VINList[0]?.division,
      items: itemsList.map(({ itemDescription, techRefId, type, ...item }) => item),
    };
    let response = await ATPCheck(data);
    if (response?.status === 200) {
      let responseData = response.data.data.items;
      responseData = responseData.sort(function (a, b) {
        return Number(a.itemNo) > Number(b.itemNo) ? 1 : Number(b.itemNo) > Number(a.itemNo) ? -1 : 0;
      });
      let index = 0;
      let materialData = itemsList.map((items) => {
        let data = {
          materialId: items.itemCode,
          itemDescription: items.itemDescription,
          totalQty: items.orderQuantity,
          techRefId: items.techRefId,
          type: items.type,
        };
        if (responseData.length) {
          if (Number(items.itemNo) === Number(responseData[index].itemNo)) {
            data = {
              ...data,
              quantityATP: responseData[index].confirmedQuantity ? responseData[index].confirmedQuantity : 0,
              dateATP: responseData[index].confirmedDate,
            };
            if (index < responseData.length - 1) {
              index += 1;
            }
          } else {
            data = { ...data, quantityATP: 0, dateATP: null };
          }
          return data;
        } else {
          notification.setNotification(
            <Notification key={generate()} type={"info"} title="ATP Quantity is 0. Please validate before creating work ticket." fullBleed={true} />
          );
          data = { ...data, quantityATP: 0, dateATP: null };
          return data;
        }
      });
      handleVINRecordList(materialData, VINList);
      setMaterialsList(materialData.filter((material) => material.quantityATP >= 0));
    } else {
      setIsCreateWorkTicketBtnDisabled(true);
      notification.setNotification(
        <Notification
          key={generate()}
          title={
            <>
              <span>We are currently experiencing issues, please </span>{" "}
              <TextLink size="small" type="inline" tabIndex="0" role="link" onClick={() => handleATPCheck(MMIDList, VINList)}>
                try again
              </TextLink>
            </>
          }
          type="error"
          fullBleed={true}
        />
      );
    }
    setIsLoading(false);
  };

  const handleVINRecordList = (materialData, VINList) => {
    let map = new Map(materialData.map((item) => [item.materialId, item]));
    VINList.map((records) => {
      records.materials.map((material) => {
        let mmid = material.materialId;
        if (map.has(mmid)) {
          let newMaterial = map.get(mmid);
          material.dateATP = newMaterial.dateATP;
          material.quantityATP = newMaterial.quantityATP;
          material.type = newMaterial.type;
          material.materialName = newMaterial.itemDescription;
        }
        return material;
      });
    });
  };

  const showDeleteIcon = (row) => (
    <div onClick={() => showDeleteMMIDModal(row)}>
      <Icon
        className="trash-icon"
        aria-label="delete"
        alt="delete-icon"
        name="trash"
        size="medium"
        color="#000000"
        style={{ cursor: "pointer", outline: "none" }}
      />
    </div>
  );

  const showDeleteMMIDModal = (row) => {
    setDeleteMMIDModal(true);
    setMaterialToBeDeleted(row);
  };

  const handleDelete = (row) => {
    let deletedMMID = materialToBeDeleted.materialId;
    let newMaterialList = materialsList.filter((list) => list.materialId !== deletedMMID);
    if (newMaterialList.length === 0) {
      currentATPRecordIndex + 1 < totalLength ? handleATPNavigation() : handleATPNavigation("prevPage");
    }
    let newVINRecordList = VINRecordList.map((recordList) => {
      let updatedList = recordList.materials.filter((material) => material.materialId !== deletedMMID);
      recordList.materials = updatedList;
      return recordList;
    });
    setVINRecordList(newVINRecordList);
    setMaterialsList(newMaterialList);
  };

  const clearFields = () => {
    setContactName({ value: "", error: false, errorText: "", success: false });
    setEmail({ value: "", error: false, errorText: "", success: false });
    setPhoneNumber({ value: "", error: false, errorText: "", success: false });
    setCreateWorkTicketModal(false);
    setMaterialsList([]);
    setVINRecordList([]);
  };

  const handleATPNavigation = (action = "") => {
    clearFields();
    currentATPRecordIndex + 1 >= totalLength
      ? action === "prevPage"
        ? lostInTransit
          ? switchTab(1)
          : switchTab(0)
        : switchTab(1)
      : setCurrentATPRecordIndex(currentATPRecordIndex + 1);
  };

  const handleWorkTicketCreation = async () => {
    updateNewrelicStats();
    notification.setNotification(null);
    setIsLoading(true);
    let workTicketRequest = VINRecordList.map((recordList) => {
      return {
        vinId: recordList.id,
        vin: recordList.vin ? recordList.vin : "",
        year: recordList.year ? recordList.year : "",
        make: recordList.make ? recordList.make : "",
        model: recordList.model ? recordList.model : "",
        installType: recordList.installType,
        // addressOne: recordList.addressOne,
        // addressTwo: recordList.addressTwo,
        // city: recordList.city,
        // stateCode: recordList.stateCode,
        // country: recordList.country,
        // zipCode: recordList.zipCode,
        addressOne: Array.isArray(data[0]?.shippingAddress) ? newAddress.address1.value : recordList.addressOne,
        addressTwo: Array.isArray(data[0]?.shippingAddress) ? newAddress.address2.value : recordList.addressTwo,
        city: Array.isArray(data[0]?.shippingAddress) ? newAddress.city.value : recordList.city,
        stateCode: Array.isArray(data[0]?.shippingAddress)
          ? newAddress.state.value?.erpStateCode
            ? newAddress.state.value?.erpStateCode
            : newAddress.state.value
          : recordList.stateCode,
        country: Array.isArray(data[0]?.shippingAddress)
          ? newAddress.country.value?.code
            ? newAddress.country.value?.code
            : newAddress.country.value
          : recordList.country,
        zipCode: Array.isArray(data[0]?.shippingAddress) ? newAddress.zip.value : recordList.zipCode,
        materials: recordList.materials
          .map((item) => {
            let defaultType = "";
            let sapId = null;
            let contractId = null;
            let contractName = null;
            if (lostInTransit) {
              defaultType = defaultExchangeType.filter((records) => records.materialId == item.materialId)[0]?.defaultExchangeType ?? "";
              sapId = item?.sapValue ? item.sapValue : null;
              contractId = item?.sapId ? item.sapId : null;
              contractName = item?.sapName?.length > 0 ? item?.sapName : null;
            } else {
              if (recordList?.vin?.length > 0 || recordList?.year || recordList?.make?.length > 0 || recordList?.model?.length > 0) {
                sapId = item?.vmfSapIdVal ? item.vmfSapIdVal : null;
                contractId = item?.vmfSapId ? item.vmfSapId : null;
                contractName = item?.vmfSapName?.length > 0 ? item?.vmfSapName : null;
              } else {
                sapId = item?.sapIdVal ? item.sapIdVal : null;
                contractId = item?.sapId ? item.sapId : null;
                contractName = item?.sapName?.length > 0 ? item?.sapName : null;
              }
            }
            return {
              id: item.mid ? item.mid : item.id,
              materialId: item.materialId,
              materialName: item.materialName,
              quantityATP: item.quantityATP ? Number(item.quantityATP) : "",
              dateATP: item.dateATP ? item.dateATP : "",
              summaryQty: item.orderQty ? item.orderQty : 1,
              materialLineId: item.materialLineId ? item.materialLineId : "",
              unitMeasure: "EA",
              techRefId: item.techRefId,
              processingCode: lostInTransit ? defaultType : item.type,
              installedQuantity: "",
              chargePrice: !isSourceCSP.isSourceCSP ? item?.chargeAmt : 0,
              family: "",
              customerCharge: "",
              serviceProcessType: "",
              invoiceRelevant: "",
              endTime: "",
              customerPONumber: cspSessionData?.CSP_CUstomer_PO_Number,
              customerPONumberKut: cspSessionData?.CSP_CUstomer_PO_Number,
              baseItemId: item.bundleId,
              esnToBeReturned: item?.esnToBeReturned ? item.esnToBeReturned : "",
              sapId: sapId ? sapId : null,
              contractId: contractId ? contractId : null,
              contractName: contractName?.length > 0 ? contractName : null,
              orderType: item.orderType,
              contractOverride: item.upsell == true ? "Yes" : item.upsell == false ? "No" : null,
            };
          })
          .filter((material) => material.quantityATP >= 0),
      };
    });
    let data1 = {
      createdBy: `${cspSessionData?.firstName} ${cspSessionData?.lastName}`,
      sourceWorkticket: workticketUpdateData?.workTicketId
        ? workticketUpdateData?.workTicketId.includes("W")
          ? null
          : workticketUpdateData.workTicketId
        : null,
      contactName: contactName.value,
      emailAddress: email.value,
      phoneNumber: phoneNumber.value,
      assignTo: cspSessionData?.Employee_ID,
      crmReferenceId:
        cspSessionData?.source === "CSP"
          ? cspSessionData?.CSP.length
            ? cspSessionData?.CSP.filter((csp) => csp?.DefaultCSP === "1")[0].CRM_Ref_ID
            : ""
          : cspSessionData?.CASEDetails.length
          ? cspSessionData?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseNumber
          : "",
      currencyCode: cspSessionData?.currencyCode
        ? cspSessionData?.currencyCode
        : salesOrgBasedCurrency["SO_" + VINRecordList[0]?.salesOrg?.split(" ")[0]]?.currency
        ? salesOrgBasedCurrency["SO_" + VINRecordList[0]?.salesOrg?.split(" ")[0]]?.currency
        : "",
      documentType: cspSessionData?.source === "CSP" ? "ZINS" : "ZSRQ",
      documentDate: moment(new Date()).format("YYYYMMDD"),
      salesOrg: salesOrgId ? salesOrgId : VINRecordList[0]?.salesOrg?.split(" ")[0] ? VINRecordList[0]?.salesOrg?.split(" ")[0] : "",
      distributionChannel: VINRecordList[0]?.distributionChannel,
      division: VINRecordList[0]?.division,
      // contractAccount: cspSessionData?.CONTRACT_ACCOUNT_ID[0],
      contractAccount:
        contractAccountNumber.length === 1
          ? contractAccountNumber[0]
          : lostInTransit
          ? contractAccountNumber[0]
          : selectedContractAccountNumber.value,
      erpVendorId: "",
      salesRepId: cspSessionData?.Employee_ID,
      notesToTechnician: "",
      vendorMessage: "",
      notesForWarehouse: notesToWareHouse,
      subStatus: ERPSubStatus ? ERPSubStatus : "",
      oldDeliveryNo: originalDONumber ? originalDONumber : "",
      requestDate: requestedDeliveryDate ? moment(requestedDeliveryDate).format("YYYYMMDD") : "",
      erpBusinessPartnerId: cspSessionData?.ERP_Partner_Number,
      providerOrder: cspSessionData?.source === "CSP" ? providerOrder : "",
      erpTicketType: cspSessionData?.source === "CSP" ? "ZINS" : "ZSRQ",
      assignToName: `${cspSessionData?.firstName} ${cspSessionData?.lastName}`,
      erpProductRecipientPartyId: cspSessionData?.ResellerBusinessPartnerNumber || cspSessionData?.ERP_Partner_Number,
      workticketRequest: workTicketRequest,
      createdByEmail: cspSessionData?.email,
      createdByEid: cspSessionData?.Employee_ID,
      customerName: cspSessionData?.AccountName,
      customerNumber: cspSessionData?.AccountNo,
      cspId:
        cspSessionData?.source?.toLowerCase() === "csp" && cspSessionData.CSP.length
          ? cspSessionData.CSP.filter((csp) => csp.DefaultCSP === "1")[0].CSPID
          : "",
      caseId:
        cspSessionData?.source?.toLowerCase() !== "csp" && cspSessionData.CASEDetails.length
          ? cspSessionData.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseSFDCID
          : "",
      caseNumber:
        cspSessionData?.source?.toLowerCase() !== "csp" && cspSessionData.CASEDetails.length
          ? cspSessionData?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0]?.CaseNumber
          : "",
      accountId: cspSessionData?.AccountID,
      assignedTo:
        cspSessionData?.source === "CSP"
          ? cspSessionData?.CSP.length
            ? cspSessionData?.CSP.filter((csp) => csp?.DefaultCSP === "1")[0].CSPOwner
            : null
          : cspSessionData?.CASEDetails.length
          ? cspSessionData?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseOwner
          : null,
      assignedToEmail:
        cspSessionData?.source === "CSP"
          ? cspSessionData?.CSP.length
            ? cspSessionData?.CSP.filter((csp) => csp?.DefaultCSP === "1")[0].CSPOwnerEmail
            : null
          : cspSessionData?.CASEDetails.length
          ? cspSessionData?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseOwnerEmail
          : null,
      assignedToEid:
        cspSessionData?.source === "CSP"
          ? cspSessionData?.CSP.length
            ? cspSessionData?.CSP.filter((csp) => csp?.DefaultCSP === "1")[0].CSPOwnerEId
            : null
          : cspSessionData?.CASEDetails.length
          ? cspSessionData?.CASEDetails.filter((cases) => cases.DefaultCase === "1")[0].CaseOwnerEId
          : null,
      partners: [
        {
          party: cspSessionData?.ResellerBusinessPartnerNumber || cspSessionData?.ERP_Partner_Number || "",
          partRoleCode: "AG",
        },
        {
          party: cspSessionData?.ERP_Primary_Contact_NUmber ?? "",
          partRoleCode: "AP",
        },
        {
          party: cspSessionData?.ERP_Partner_Number,
          partRoleCode: "WE",
          accountName: cspSessionData?.AccountName,
          contactName: contactName.value,
          contactPhone: phoneNumber.value,
          contactEmail: email.value,
          // street: spiltAddress.address1 + " " + spiltAddress.address2,
          // city: spiltAddress.city,
          // state: spiltAddress.state,
          // country: spiltAddress.country,
          // zip: spiltAddress.zip,
          street: Array.isArray(data[0]?.shippingAddress)
            ? newAddress.address1.value + " " + newAddress.address2.value
            : spiltAddress.address1 + " " + spiltAddress.address2,
          city: Array.isArray(data[0]?.shippingAddress) ? newAddress.city.value : spiltAddress.city,
          state: Array.isArray(data[0]?.shippingAddress)
            ? newAddress.state.value?.erpStateCode
              ? newAddress.state.value?.erpStateCode
              : newAddress.state.value
            : spiltAddress.state,
          country: Array.isArray(data[0]?.shippingAddress)
            ? newAddress.country.value?.code
              ? newAddress.country.value?.code
              : newAddress.country.value
            : spiltAddress.country,
          zip: Array.isArray(data[0]?.shippingAddress) ? newAddress.zip.value : spiltAddress.zip,
          telephone: spiltAddress.telephone,
        },
      ],
    };
    let response = await createWorkticket(data1);
    if (response.status === 200 && response?.data?.message.toLowerCase() === "success" && response?.data?.data?.workticketId) {
      // quantityTransactionCall(response?.data?.data?.workticketId);
      notification.setNotification(<Notification key={generate()} title="WorkTicket created successfully" type="success" />);
      setTimeout(() => {
        notification.setNotification(null);
        if (lostInTransit) {
          handleWorkTicketUpdate();
        }
        handleATPNavigation();
        setIsLoading(false);
      }, 3000);
    } else {
      notification.setNotification(
        <Notification key={generate()} title="We are currently experiencing issues, please try again" type="error" fullBleed={true} />
      );

      setIsLoading(false);
    }
  };

  /* Start - Changes are done as part of VZC42148-82 & VZC42148-292 */
  // const quantityTransactionCall = async (workticketId) => {
  //   updateNewrelicStats();
  //   let transaction = [];
  //   let matInfo;
  //   let qtyTransactionPayload;
  //   let materialsArray = [];
  //   let records = _.cloneDeep(data[currentATPRecordIndex]);
  //   Object.values(records).map((record) => {
  //     record.map((material) => {
  //       materialsArray.push(...material.materials);
  //     });
  //   });
  //   let MMIDGrouped = groupedByMMID(materialsArray);
  //   quantityInfoArr.push(...MMIDGrouped);
  //   quantityInfoArr.map((mmid) => {
  //     let sapId = mmid.vmfSapIdVal ? mmid.vmfSapIdVal : mmid.sapIdVal;
  //     let cspProduct = cspDetails?.cspDetails?.cspData?.data?.data?.Product?.filter((prod) => prod.SAPProductID === sapId);
  //     let sfdcQty = cspProduct?.length > 0 ? cspProduct[0]?.Quantity : 0;
  //     let orderedQty = mmid.totalQty ? mmid.totalQty : 1;
  //     let upsell = mmid.upsell ? mmid.upsell : false;
  //     matInfo = {
  //       sfdcQty: sfdcQty,
  //       sapId: sapId,
  //       orderedQty: orderedQty,
  //       upsell: upsell ? upsell : false,
  //       techRefId: mmid.techRefId,
  //       processingCode: mmid.vmfType === undefined ? mmid.type : mmid.vmfType ?? "", //VZC42148-1104
  //     }; /* Default as false if no upsell info */
  //     transaction.push(matInfo);
  //   });
  //   qtyTransactionPayload = {
  //     workticketId: workticketId,
  //     createdBy: `${cspSessionData?.firstName} ${cspSessionData?.lastName}`,
  //     cspId: cspDetails?.cspDetails?.cspData?.data?.data?.CSP.filter((csp) => csp.DefaultCSP === "1")[0].CSPID,
  //     salesOrg: cspDetails?.cspDetails?.cspData?.data?.data?.CSP.filter((csp) => csp.DefaultCSP === "1")[0].Opp_Sales_Org,
  //     transaction,
  //   };
  //   let materialsWithMissingData = transaction.filter((data) => data.sapId === null && data.upsell === false).map((data) => data.materialId);
  //   if (materialsWithMissingData.length > 0) {
  //     return notification.setNotification(
  //       <Notification
  //         key={generate()}
  //         type="error"
  //         title={"SAP ID is missing for the following material(s) [" + materialsWithMissingData.join() + "]"}
  //         fullBleed={true}
  //       />
  //     );
  //   } else if (qtyTransactionPayload.cspId === null)
  //     return notification.setNotification(
  //       <Notification
  //         key={generate()}
  //         type="error"
  //         title={"Quantity Transaction failed due to missing information. Kindly contact IT team for support. Missing information: CSP ID"}
  //         fullBleed={true}
  //       />
  //     );
  //   if (isSourceCSP.isSourceCSP) await quantityTransaction(qtyTransactionPayload);
  //   //if (response?.statusCode === 200) {
  //   //eslint-disable-next-line no-console
  //   //  console.log(response?.statusCode, "Quantity Transaction : response.statusCode: ");
  //   // }
  // };
  /* End of VZC42148-82 & VZC42148-292 */

  const handleFields = (field, value) => {
    notification.setNotification(null);
    switch (field) {
      case "name": {
        if (value && value.length) {
          setContactName({ value, error: false, errorText: "Enter name", success: true });
        } else {
          setContactName({ value: "", error: true, errorText: "Enter name", success: false });
        }
        break;
      }
      case "email": {
        if (value && value.length) {
          if (EMAIL_REGEX_C360.test(value)) {
            setEmail({ value, error: false, errorText: "Enter email", success: true });
          } else {
            setEmail({ value, error: true, errorText: "Enter a valid email", success: false });
          }
        } else {
          setEmail({ value: "", error: true, errorText: "Enter email", success: false });
        }
        break;
      }
      case "phone": {
        let phoneRegex = /^([^a-zA-Z])+$/;
        if (value && value.length) {
          if (phoneRegex.test(value)) {
            setPhoneNumber({ value, error: false, errorText: "Enter phone number", success: true });
          }
          //  else {
          //   setPhoneNumber({ value, error: true, errorText: "Enter a valid phone number", success: false });
          // }
        } else {
          setPhoneNumber({ value, error: true, errorText: "Enter phone number", success: false });
        }
        break;
      }
      case "soldAddress": {
        if (value && value.length) {
          setSoldAddress({ value, error: false, errorText: "", success: true });
        }
        break;
      }
      case "billAddress": {
        if (value && value.length) {
          setBillAddress({ value, error: false, errorText: "", success: true });
        }
        break;
      }
      case "payerAddress": {
        if (value && value.length) {
          setPayerAddress({ value, error: false, errorText: "", success: true });
        }
        break;
      }
      case "contractAccountNumber": {
        if (isSourceCSP.isSourceCSP) {
          value && value.length
            ? setSelectedContractAccountNumber({ value, error: false, errorText: "Select contract account number", success: true })
            : setSelectedContractAccountNumber({ value: "", error: true, errorText: "Select contract account number", success: false });
        } else {
          value && value.length
            ? setSelectedContractAccountNumber({ value, error: false, errorText: "Enter contract account number", success: true })
            : setSelectedContractAccountNumber({ value: "", error: true, errorText: "Enter contract account number", success: false });
        }
      }
    }
  };

  const atpQuantityInfoBanner = useMemo(
    () =>
      materialsList.filter((material) => Number(material.quantityATP) < 1).length > 0 && (
        <InfoNotification key={generate()} type={"info"} title="ATP Quantity is 0. Please validate before creating work ticket." fullBleed={true} />
      ),
    [materialsList]
  );
  const notesToWareHouseChange = (e) => {
    setNotesToWareHouse(e.target.value);
  };

  const handleCancel = async () => {
    updateNewrelicStats();
    notification.setNotification(null);
    setIsLoading(true);
    let VINId = VINRecordList.map((VINs) => VINs.id);
    let response = await deleteVehicle({
      modifiedBy: `${cspDetails.cspDetails.firstName} ${cspDetails.cspDetails.lastName}`,
      id: VINId,
    });
    if (response.status === 200 && response?.data?.message.toLowerCase() === "success") {
      setTimeout(() => {
        notification.setNotification(null);
        switchTab(1);
        setIsLoading(false);
      }, 3000);
    } else {
      notification.setNotification(
        <Notification key={generate()} title="We are currently experiencing issues, please try again" type="error" fullBleed={true} />
      );
      setIsLoading(false);
    }
  };

  const handleWorkTicketUpdate = async () => {
    setIsLoading(true);
    let response = await workticketUpdate(workticketUpdateData);
    if (response?.status === 200 && response?.data) {
      sessionStorage.setItem("workticketUpdateError", "");
      setIsLoading(false);
    } else {
      sessionStorage.setItem("workticketUpdateError", workticketUpdateData.workTicketId);
      setIsLoading(false);
    }
  };

  return (
    <>
      {lostInTransit && (
        <>
          {notification.notification}
          <Spacer top="1.5rem" />
        </>
      )}
      <Loader active={isLoading} fullscreen />
      {createWorkTicketModal && (
        <CreateWorkTicketModal
          lostInTransit={lostInTransit}
          quantityValidationCheck={quantityValidationCheck}
          createWorkTicketModal={createWorkTicketModal}
          setCreateWorkTicketModal={setCreateWorkTicketModal}
          handleWorkTicketCreation={handleWorkTicketCreation}
        />
      )}
      <Header tabIndex={0}>{`ATP Date Check (${currentATPRecordIndex + 1}/${totalLength})`}</Header>
      {deleteMMIDModal && <DeleteMMIDModal deleteMMIDModal={deleteMMIDModal} setDeleteMMIDModal={setDeleteMMIDModal} deleteMMID={handleDelete} />}
      <SLGridLayout>
        <SLRowLayout css={{ marginTop: "1.25rem" }}>
          <CompactCol colSizes={{ mobile: 3, tablet: 7, desktop: 3 }}>
            <CustomInput
              width="100%"
              type="text"
              label="Contact Name"
              value={contactName.value}
              error={contactName.error}
              errorText={contactName.errorText}
              success={contactName.success}
              maxLength={50}
              paddingRight="10px"
              onChange={(e) => handleFields("name", e.target.value)}
            />
          </CompactCol>
          <CompactCol colSizes={{ mobile: 3, tablet: 7, desktop: 3 }}>
            <CustomInput
              width="100%"
              type="text"
              label="Email Address"
              value={email.value}
              error={email.error}
              errorText={email.errorText}
              success={email.success}
              paddingRight="10px"
              onChange={(e) => handleFields("email", e.target.value)}
            />
          </CompactCol>
          <CompactCol colSizes={{ mobile: 3, tablet: 7, desktop: 3 }}>
            <CustomInput
              width="100%"
              type="text"
              label="Phone Number"
              value={phoneNumber.value}
              error={phoneNumber.error}
              errorText={phoneNumber.errorText}
              success={phoneNumber.success}
              // maxLength={10}
              paddingRight="10px"
              onChange={(e) => handleFields("phone", e.target.value)}
            />
          </CompactCol>
          {lostInTransit ? (
            <CompactCol colSizes={{ mobile: 3, tablet: 7, desktop: 3 }}>
              <CustomInput
                width="100%"
                type="text"
                label="Contract Account Number"
                value={contractAccountNumber[0] || ""}
                disabled
                paddingRight="10px"
              />
            </CompactCol>
          ) : isSourceCSP.isSourceCSP ? (
            <CompactCol colSizes={{ mobile: 3, tablet: 7, desktop: 3 }}>
              {contractAccountNumber.length <= 1 ? (
                <CustomInput
                  width="100%"
                  type="text"
                  label="Contract Account Number"
                  value={contractAccountNumber[0] || ""}
                  disabled
                  paddingRight="10px"
                />
              ) : (
                <CustomDropdown
                  width="100%"
                  label="Contract Account Number"
                  errorText={selectedContractAccountNumber.errorText}
                  error={selectedContractAccountNumber.error}
                  value={selectedContractAccountNumber.value ? selectedContractAccountNumber.value : "Please select"}
                  onChange={(e) => handleFields("contractAccountNumber", e.target.value)}
                  paddingRight="10px"
                  marginBottom="10px"
                >
                  <option disabled>Please select</option>
                  {contractAccountNumber.map((record, index) => (
                    <option key={index}>{record}</option>
                  ))}
                </CustomDropdown>
              )}
            </CompactCol>
          ) : (
            <CompactCol colSizes={{ mobile: 3, tablet: 7, desktop: 3 }}>
              {contractAccountNumber.length <= 1 ? (
                <CustomInput
                  width="100%"
                  type="text"
                  label="Contract Account Number"
                  value={contractAccountNumber[0] || ""}
                  disabled
                  paddingRight="10px"
                />
              ) : (
                <CustomDropdown
                  width="100%"
                  label="Contract Account Number"
                  errorText={selectedContractAccountNumber.errorText}
                  error={selectedContractAccountNumber.error}
                  value={selectedContractAccountNumber.value ? selectedContractAccountNumber.value : "Please select"}
                  onChange={(e) => handleFields("contractAccountNumber", e.target.value)}
                  paddingRight="10px"
                  marginBottom="10px"
                >
                  <option disabled>Please select</option>
                  {contractAccountNumber.map((record, index) => (
                    <option key={index}>{record}</option>
                  ))}
                </CustomDropdown>
              )}
            </CompactCol>
          )}
        </SLRowLayout>
        {Array.isArray(data[0]?.shippingAddress) ? (
          <ValidateAddress
            address={newAddress}
            setAddress={setNewAddress}
            vehicleData={oldAddress}
            showHistoricalAddressDropdown={!oldAddress}
            setLoader={setIsLoading}
          />
        ) : (
          <div></div>
        )}

        <SLRowLayout css={{ marginTop: "1rem" }}>
          {Array.isArray(data[0]?.shippingAddress) ? (
            <div></div>
          ) : (
            <CompactCol colSizes={{ mobile: 3, tablet: 7, desktop: 8 }}>
              <CustomInput width="100%" disabled type="text" label="Shipping Address" paddingRight="10px" value={address} />
            </CompactCol>
          )}
          <CompactCol colSizes={{ mobile: 3, tablet: 7, desktop: 2 }}>
            <CustomInput width="100%" disabled type="text" label="Sales Org" paddingRight="10px" value={salesOrg} />
          </CompactCol>
          <CompactCol colSizes={{ mobile: 3, tablet: 7, desktop: 2 }}>
            <CustomInput width="100%" disabled type="text" label="Install Type" paddingRight="10px" value={installType} />
          </CompactCol>
        </SLRowLayout>
        <SLRowLayout css={{ marginTop: "1.25rem" }}>
          <CompactCol colSizes={{ mobile: 3, tablet: 7, desktop: 12 }}>
            <TextAreaWrapper>
              <TextArea
                label="Notes to Warehouse"
                aria-label="Notes to Warehouse"
                required={false}
                readOnly={false}
                disabled={false}
                maxLength={255}
                value={notesToWareHouse}
                onChange={(e) => notesToWareHouseChange(e)}
                css={{
                  fontSize: "12px",
                  fontFamily: "Verizon-NHG-eTX",
                  letterSpacing: "0",
                }}
              />
            </TextAreaWrapper>
          </CompactCol>
          <CompactCol css={{ marginTop: "1.25rem" }} colSizes={{ mobile: 3, tablet: 7, desktop: 4 }}>
            <CustomInput
              width="100%"
              disabled
              type="text"
              label="Business Partner ID"
              paddingRight="10px"
              value={cspSessionData?.ERP_Partner_Number}
            />
          </CompactCol>
        </SLRowLayout>
      </SLGridLayout>

      <AccordionStyle>
        <Accordion activeIndex={-1} expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
          <AccordionTab header="Purchase Information">
            <SLGridLayout>
              <SLRowLayout css={{ marginTop: "1rem" }}>
                <CompactCol colSizes={{ mobile: 3, tablet: 7, desktop: 6 }}>
                  <CustomInput width="100%" disabled type="text" label="Brand" paddingRight="10px" value={cspSessionData?.Account_Brand} />
                </CompactCol>
                <CompactCol colSizes={{ mobile: 3, tablet: 7, desktop: 6 }}>
                  <CustomInput
                    width="100%"
                    disabled
                    type="text"
                    label="Account ID"
                    paddingRight="10px"
                    value={cspSessionData?.ResellerBusinessPartnerNumber}
                  />
                </CompactCol>
              </SLRowLayout>
            </SLGridLayout>
          </AccordionTab>
        </Accordion>
      </AccordionStyle>

      {vehicleInfo.length > 0 ? (
        <Spacer top="2.5rem" bottom="1rem">
          <LargeText tabIndex={0}>Vehicle Information</LargeText>
          <Spacer top="10px" />
          <SmallText>{Array.from(vehicleInfo).join(", ")}</SmallText>
        </Spacer>
      ) : (
        <></>
      )}
      <Spacer bottom="1rem" />
      <div tabIndex={0} aria-label="table" css={{ paddingRight: "10px" }}>
        <GridComponent
          isSelectionEnabled={false}
          isExportEnabled={false}
          noDataMessage="No data available to display"
          rows={materialsList}
          columns={ATP_SUMMARY_CHECK_COL}
          hideGlobalFilter={true}
          paginator={false}
          showActionIcon={showDeleteIcon}
          headerBanner={atpQuantityInfoBanner}
        />
      </div>
      <Spacer bottom="1rem" />
      <FlexRow>
        <CustomSLButton primary disabled={isCreateWorkTicketBtnDisabled} onClick={() => setCreateWorkTicketModal(true)}>
          Create Work Ticket
        </CustomSLButton>
        <CustomSLButton
          secondary
          onClick={() => {
            notification.setNotification(null), lostInTransit ? handleCancel() : switchTab(0);
          }}
        >
          Cancel
        </CustomSLButton>
      </FlexRow>
    </>
  );
}

export const ATPSummaryCheckMetaData = {
  name: "ATP Date Check",
  id: "atp_summary_check",
  component: lazy(() => import("./ATPSummaryCheck")),
  route: "/atp_summary_check",
};
