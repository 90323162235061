import { useState, useEffect } from "react";
import { postRequest } from "../RestServices";
import * as urls from "../../api/searchRevealUnits/url";
import { getProperty } from "../../utility/getProperty";
import isEmpty from "lodash/isEmpty";

export const useToCreateVehicles = () => {
  const [payload, setPayload] = useState({});
  const [vehicleData, setVehicleData] = useState([]);
  const [isSubmit, setSubmit] = useState(false);
  const [isVehicleSubmitLoading, setVehicleSubmitLoading] = useState(false);
  const [isError, setError] = useState("");

  useEffect(() => {
    const submitVehicle = async () => {
      try {
        setVehicleSubmitLoading(false);
        const _response = await postRequest(urls.CREATE_REVEAL_UNITS, payload);
        const _vtuVehicles = getProperty(_response, "data.data", []);
        setError("");
        setVehicleData(_vtuVehicles);
      } catch (err) {
        setError(err.response.data.message);
      } finally {
        setVehicleSubmitLoading(false);
      }
    };

    if (!isEmpty(payload) && isSubmit) {
      submitVehicle();
    }
  }, [payload, isSubmit]);

  return { vehicleData, isVehicleSubmitLoading, isError, setPayload, setSubmit };
};
