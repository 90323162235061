/** checkin endpoints */
const baseVtuServiceUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_VTU}`;
const baseRevealServiceUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REVEAL}`;
const baseCameraServiceUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CAMERA}`;

export const GET_DEVICE_VEHICLES = baseVtuServiceUrl + `/vehicle`;
export const GET_REVEAL_UNITS = baseVtuServiceUrl + `/vehiclesbyesn`;
export const SEARCH_REVEAL_UNITS = baseVtuServiceUrl + `/revealvehiclesbyesn`;
export const CREATE_REVEAL_UNITS = baseVtuServiceUrl + `/vehicle/create`;

/** export const GET_REVEAL_UNITS = baseUrl + `/getVehicleByESN`;  */
const baseUrlCamera = "./camera";
const revealUrl = "./reveal/api/v1";

export const GET_REVEAL_CAMS_ACCT_UID_CONTROLU = revealUrl + `/getCameraByUAcctId`;
export const GET_REVEAL_CAMS_CAMU_ESN = baseCameraServiceUrl + `/cameraBySerialNumber`;
export const GET_REVEAL_CAMS_DRID = baseCameraServiceUrl + `/cameraByDrid`;
export const GET_REVEAL_CAMS_ACCTID = baseCameraServiceUrl + `/cameraByAcctId`;
export const GET_REVEAL_CAMERA_BY_CAMERA_ACCTID = baseCameraServiceUrl + `/fetchCamerasByCameraAcctId`;
export const SEARCH_REVEAL_CAMERA_BY_UNIT_ESN = baseCameraServiceUrl + `/searchCameraByUnitEsn`;
export const SEARCH_REVEAL_CAMERAS_BY_CONTROL_ESN = baseCameraServiceUrl + `/searchCamerasByControlUnitEsn`;
export const GET_REVEAL_CAMS_VTU_ESN = baseCameraServiceUrl + `/cameraByEsn`;

/** Mock URLS */
export const GET_REVEAL_CAMS_THREE_PARAM = baseUrlCamera + `/getCameraMockThreeParam`;
export const GET_REVEAL_CAMS_TWO_PARAM = baseUrlCamera + `/getCameraMockTwoParam`;
